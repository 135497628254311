import React from 'react';

import NewsCard from './NewsCard';

export default function NewsPageContent({news}) {

    return (
        <div className={"container"}>
            <div className={"row"}>
                {
                    news.map(_news => <NewsCard news={_news} key={_news.product_code} />)
                }
            </div>
        </div>
    )
}