import React, {useEffect, useState} from "react";
import requests from "../../requests";
import constants from "../../constants";

export default function ListItem(props) {

    const { field, value, onChange, requireParam } = props;
    const [optionsState, setOptionsState] = useState({});
    const [listItems, setListItems] = useState([]);

    async function initialState() {
        let requestUrl = field.auto_populate.url;
        if (requireParam) {
            requestUrl += requireParam;
        }
        let responseData = await requests.getData(requestUrl , constants.ACCESS_TOKEN);

        let items = responseData[field.auto_populate.key];
        let defaultItem = undefined;
        if (value !== undefined) {
            defaultItem = items.find(item => item.key === value);
        } else {
            defaultItem = items.find(item => item.defaultItem === 1);
        }
        if (defaultItem !== undefined && defaultItem !== null) {
            setListItems(items);
            setOptionsState(defaultItem);
            let event = {}
            event["target"] = {
                id: field.key,
                value: defaultItem.key
            }
            event.preventDefault = () => { };
            await onChange(event);
        }
    }

    async function handleOptionsChange(event) {
        let val = event.target.value;
        let options = listItems.find(ops => ops.key === val);
        setOptionsState(options);
        await onChange(event);
    }

    useEffect(() => {
        initialState().then();

        return () => {
            setOptionsState({});
            setListItems([]);
        }
    }, []);

    return (
        <div className="form-group col-xs-12 col-12 col-md-6 p-1">
            <label>{field.label}</label>
            <select class="form-control" id={field.key} name={field.key} value={value} onChange={handleOptionsChange}>
                {listItems.map(listItem => <option key={listItem.key} value={listItem.key}>{listItem.value}</option>)}
            </select>
        </div>
    )
}