import requests from "../../requests";
import constants from "../../constants";
import trigger from "../../util/trigger";
import {Form} from "react-bootstrap";
import React from "react";

export default function AutoPopulate(props) {

    const { field, createFields, onChange, value } = props;
    let data = {}
    if (value !== null && value !== undefined) {
    } else if (field.auto_populate !== null && createFields[field.key] === undefined) {
        requests.getData(field.auto_populate.url, constants.ACCESS_TOKEN).then(result => {
            data = result;
            trigger.triggerNativeOnChangeInput(document.getElementById(field.key), data[field.auto_populate.key]);
        });
    }
    return (
        <div key={field.key} className="col-xs-12 col-12 col-md-6 p-1">
            <Form.Label>{field.label}</Form.Label>
            <input type="input" className="form-control"
                   id={field.key}
                   onChange={onChange}
                   value={value}
                   readOnly />
        </div>
    )
}