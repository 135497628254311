import React, {useState, useEffect } from 'react';
import Sidebar from '../components/Sidebar';
import Navbar from '../components/Navbar';
import LibraryContent from '../content/LibraryContent';

const LibraryPage = () => {

    return (
        <div className="g-sidenav-show bg-gray-100">
            <Sidebar />
            <div id="content">
                <Navbar />
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-12 mb-3 mt-3">
                            <div className="form-inline w-100 col-auto d-inline-block shadow">
                                <LibraryContent domain={"https://emtabb.com"} />
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </div>
    );
}

export default LibraryPage;