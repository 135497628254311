import requests from "../../requests";
import constants from "../../constants";
import trigger from "../../util/trigger";
import {Form} from "react-bootstrap";
import React from "react";

export default function Statistic(props) {

    const { field, onChange, changeData, createFields, value } = props;

    let data = {}

    if (changeData !== undefined) {
        let queryParams = "";
        const require_keys = field.auto_populate.require_key;
        let status = false;
        require_keys.forEach(require_key => {
            if (changeData[require_key] !== undefined) {
                status = true;
                queryParams += `${require_key}=${changeData[require_key]}`;
            } else {
                status = false;
            }
        });
        if (status) {
            requests.getData(field.auto_populate.url + "?" + queryParams, constants.ACCESS_TOKEN).then(result => {
                data = result;
                trigger.triggerNativeOnChangeInput(document.getElementById(field.key), data[field.auto_populate.key]);
            });
        }
    }

    return (
        <div key={field.key} className="col-xs-12 col-12 col-md-6 p-1">
            <Form.Label>{field.label}</Form.Label>
            <input type="input" className="form-control"
                   id={field.key}
                   onChange={onChange}
                   value={value}
                   readOnly />
        </div>
    )
}