import React, { useState, useEffect } from "react";
import AbstractDataGrid from "../../content/datagrid/AbstractDataGrid";
import requests from "../../requests";
import constants from "../../constants";
import userDataService from "../../services/userDataService";
import LoadingPage from "../LoadingPage";
import Sidebar from "../../components/Sidebar";
import Navbar from "../../components/Navbar";
import {buildTemplateDatagrid} from "../../util/datagrid";

export default (props) => {

    const [loaded, setLoaded] = useState(false);
    const [categoryMasterList, setCategoryMasterList] = useState([]);
    const [fields, setFields] = useState([]);


    useEffect(() => {
        initialDatagrid().then(result => {
            setLoaded(true)
            setCategoryMasterList(result.categoryMasterList);
            setFields(result.fields);
        }).catch();
        return () => {
            setCategoryMasterList([]);
            setFields([]);
            setLoaded(false);
        }
    }, []);

    if (loaded) {
        return (
            <div className="g-sidenav-show  bg-gray-100">
                <Sidebar />
                <div id="content">
                    <Navbar navbarItems={constants.NAVBAR_PRODUCT_ITEMS}/>
                    <div className="container" >
                        <AbstractDataGrid columns={fields} datasource={categoryMasterList} />
                    </div>
                </div>
            </div>
        )
    } else {
        return <LoadingPage />
    }

}

async function initialDatagrid() {
    let access_token = requests.getSessionStorage(constants.ACCESS_TOKEN);
    if ( access_token !== "") {
        requests.setCookie(constants.ACCESS_TOKEN, access_token, constants.LONG_EXPIRY_DATE);
    }

    let userData = await userDataService.loadUserData();
    const SPACE_NAME = userData.space;

    let categoryMasterList = [];

    if (userData) {
        let responseProduct = await requests.getData(`/api/productservice/category/data-grid?space=${SPACE_NAME}`, constants.ACCESS_TOKEN);
        categoryMasterList = responseProduct.space;
    }

    // let responseFields = await requests.getData(`/api/space/space-structure/category-master/create-form`, constants.ACCESS_TOKEN);
    console.log(Object.keys(categoryMasterList.length !== 0 ? categoryMasterList[0] : {}));
    return {
        fields: buildTemplateDatagrid(Object.keys(categoryMasterList.length !== 0 ? categoryMasterList[0] : {})),
        categoryMasterList: categoryMasterList
    }
}


let fields = [
    {
        enableColumnFilter : true,
        accessorKey : "id",
        header : "MÃ ĐƠN"
    }, {
        enableColumnFilter : true,
        accessorKey : "customerName",
        header : "TÊN NGƯỜI MUA"
    }, {
        enableColumnFilter : true,
        accessorKey : "phone",
        header : "SỐ ĐIỆN THOẠI"
    }, {
        enableColumnFilter : true,
        accessorKey : "shippingCode",
        header : "MÃ VẬN ĐƠN"
    }, {
        enableColumnFilter : true,
        accessorKey : "shippingDepartment",
        header : "ĐƠN VỊ VẬN CHUYỂN"
    },
    {
        enableColumnFilter : true,
        accessorKey : "address",
        header : "ĐỊA CHỈ GIAO HÀNG"
    }, {
        enableColumnFilter : true,
        accessorKey : "variantList",
        header : "DANH SÁCH MUA"
    }, {
        enableColumnFilter : true,
        accessorKey : "quantity",
        header : "SÔ LƯỢNG SẢN PHẨM"
    }, {
        enableColumnFilter : true,
        accessorKey : "amount",
        header : "THÀNH TIỀN"
    }, {
        enableColumnFilter : true,
        accessorKey : "paymentMethod",
        header : "PHƯƠNG THỨC THANH TOÁN"
    }, {
        enableColumnFilter : true,
        accessorKey : "dealer",
        header : "DEALER"
    }, {
        enableColumnFilter : true,
        accessorKey : "createdDate",
        header : "NGÀY ĐẶT HÀNG"
    }, {
        enableColumnFilter : true,
        accessorKey : "status",
        header : "TRẠNG THÁI ĐƠN HÀNG"
    }
];