import React from 'react';

export default function NewsCard(props) {
    let news = props.news;

    let borderRadius = "0.2rem"
    let cardSubStyleSheet = {
        borderRadius: borderRadius
    }

    let cardImageSubStyleSheet = {
        borderTopLeftRadius: borderRadius,
        borderTopRightRadius: borderRadius
    }

    return (
        <div className="col-xs-12 col-12 col-md-6 col-lg-4 float-left d-flex justify-content-between">
            <div className="container-fluid p-2 card shadow"
                style={{ width: "100%", backgroundColor: "white", borderRadius: borderRadius,
                    borderTopLeftRadius: borderRadius, borderTopRightRadius: borderRadius }}>
                <a href={"/news/create-form?product_code=" + news.product_code} className="text-blue">
                    <img className="card-img-top"
                        style={{ width: "100%", height: "15rem", objectFit: "cover" }}
                        src={
                            news.background === ""
                                ? "https://blog.sqlauthority.com/wp-content/uploads/2007/06/null-500x259.png"
                                : `/blob/${news.background}`} alt={news.description} />
                    <h6 className="mt-3">{news.title}</h6>
                </a>
                <p className="card-text">{news.description}</p>
                <div></div>
            </div>
        </div>
    )
}