import requests from "../../requests";
import constants from "../../constants";
import userDataService from "../../services/userDataService";
import util from "../../util/util";
import React, {useEffect, useState} from "react";
import Sidebar from "../../components/Sidebar";
import Navbar from "../../components/Navbar";
import LoadingPage from "../../page/LoadingPage";
import AbstractUpdateForm from "../createform/AbstractUpdateForm";
import AbstractCreateForm from "../createform/AbstractCreateForm";

export default function VariantPriceCreateForm (props) {
    // const product_code = util.parseQuery(props.location.search).product_code;
    const [loading, setLoading] = useState(false);
    const [fields, setFields] = useState([]);
    const [product, setProduct] = useState({});

    useEffect(()=> {
        initialCreateForm().then(result => {
            setFields(result.fields);
            setProduct(result.variant_price);
            setLoading(true);
        })
        return () => {
            setFields([]);
            setProduct({});
            setLoading(false);
        }
    }, []);

    if (Array.isArray(fields) && fields.length) {
        return (
            <div className="wrapper">
                <Sidebar />
                <div id="content" className="container-fluid">
                    <Navbar navbarItems={constants.NAVBAR_PRODUCT_ITEMS}/>
                    { loading ?
                        (<AbstractCreateForm
                            fields={fields} createApi={"/api/productservice/variantprice/create"} redirectLocation={"/variantprice"}
                        />)
                        : <LoadingPage />
                    }
                </div>
            </div>
        )
    } else {
        return (
            <div className="wrapper">
                <Sidebar />
                <div id="content" className="container-fluid">
                    <h4>Lỗi tải trang.</h4>
                </div>
            </div>
        )
    }
}


async function initialCreateForm() {
    let access_token = requests.getSessionStorage(constants.ACCESS_TOKEN);
    if ( access_token !== "") {
        requests.setCookie(constants.ACCESS_TOKEN, access_token, constants.LONG_EXPIRY_DATE);
    }

    let userData = await userDataService.loadUserData();
    const SPACE_NAME = userData.space;

    let variant_price = null;
    // if (util.notNull(product_code)) {
    //     let responseProduct = await requests.getData(`/api/productservice/variantprice/mono?space=${SPACE_NAME}&product_code=${product_code}`, constants.ACCESS_TOKEN);
    //     variant_price = responseProduct.space[0];
    // }
    let responseFields = await requests.getData(`/api/space/space-structure/variantprice/create-form`, constants.ACCESS_TOKEN);
    return {
        fields: responseFields.createForm,
        variant_price: variant_price
    }
}