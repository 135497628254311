import React, { useState, useEffect } from 'react';


import HomePage from './page/HomePage';
import ProductsPage from './page/ProductsPage';
import CreateProductForm from './page/CreateProductForm';
import ProductViewPage from './page/ProductViewPage';
import LoginPage from './page/LoginPage';
import CartPage from './page/CartPage';
import OutboxDetailPage from './page/OutboxDetailPage';
import LibraryPage from './page/LibraryPage';
import NewsPage from './page/news-page/NewsPage';
import CreateFormNewsPage from './page/news-page/CreateFormNewsPage';
import FacebookPage from "./page/FacebookPage";
import MetaversePage from "./page/MetaversePage";

import { Route, BrowserRouter as Router, Switch } from 'react-router-dom';

import './App.css';
import "easymde/dist/easymde.min.css";
import VariantPriceCreateForm from "./content/variantprice/VariantPriceCreateForm";
import VariantCreateForm from "./content/variant/VariantCreateForm";
import VariantPricePage from "./page/variant/VariantPricePage";
import CategoryCreateForm from "./content/category/CategoryCreateForm";
import CategoryMasterCreateForm from "./content/categorymaster/CategoryMasterCreateForm";
import OrderServicePage from "./page/OrderServicePage";
import CategoryDataGridPage from "./page/category/CategoryDataGridPage";
import PrivacyPage from "./page/PrivacyPage";
import CategoryMasterDataGridPage from "./page/category/CategoryMasterDataGridPage";
import VariantPage from "./page/variant/VariantPage";

// https://stackoverflow.com/questions/154059/how-can-i-check-for-an-empty-undefined-null-string-in-javascript

function App() {

  return (
    <Router>
      <div>
        <Switch>
          <Route exact path="/"> <HomePage /> </Route>
          <Route exact path="/login" component={LoginPage}/>
          <Route exact path="/products" component={ProductsPage}/>
          <Route path="/products/:category" component={ProductsPage}/>
          <Route path="/cart" component={CartPage}/>
          <Route exact path="/outboxes" component={OrderServicePage}/>
          <Route exact path="/orders" component={OrderServicePage} />
          <Route path="/outbox/detail/:search_title" component={OutboxDetailPage}/>
          <Route path="/product/:search_title" component={ProductViewPage} />
          <Route exact path="/news" component={NewsPage}/>
          <Route path="/news/detail/:search_title" />
          <Route path="/news/create-form" component={CreateFormNewsPage}/>
          <Route exact path="/category" component={CategoryDataGridPage}/>
          <Route exact path="/category/create-form" component={CategoryCreateForm}/>
          <Route exact path="/category-master/create-form" component={CategoryMasterCreateForm}/>
          <Route exact path="/category-master" component={CategoryMasterDataGridPage}/>
          <Route exact path="/variantprice" component={VariantPricePage} />
          <Route exect path="/variantprice/create-form" component={VariantPriceCreateForm}/>
          <Route exact path="/variant" component={VariantPage}/>
          <Route exact path="/variant/create-form" component={VariantCreateForm}/>
          <Route path="/create-form" component={CreateProductForm}/>
          <Route path="/library" component={LibraryPage}/>
          <Route exact path="/metaverse" component={MetaversePage}/>
          <Route path="/metaverse/reels" component={FacebookPage} />
          <Route path="/privacy" component={PrivacyPage} />
          {/*<Route path="/advertisement/create-form" component={AdvertisementPage}/>*/}
          {/*<Route path="/advertisement/detail/:search_title" component={AdvertisementPage}/>*/}
        </Switch>
      </div>
    </Router>
  )
}

export default App;
