import React from "react";
import Input from './Input';
import TextBox from './TextBox';
import AutoPopulate from "./AutoPopulate";
import Editor from "./Editor";
import ListItem from "./ListItem";
import CheckSwitches from "./CheckSwitches";

export default function DetectedInput(props) {

    const { field, onChange, createFields, value, editor } = props;
    if (field.type === "input" || field.type === "number") {
        return <Input field={field} value={value} onChange={onChange} />
    } else if (field.type === "textBox") {
        return <TextBox
            field={field} value={value} onChange={onChange}
        />
    } else if (field.type === "autoPopulate") {
        return <AutoPopulate field={field} value={value} createFields={createFields} onChange={onChange} />
    } else if (field.type === "editor") {
        return <Editor value={value} field={field} onChange={onChange} />
    } else if (field.type === "switch") {
        return <CheckSwitches value={value} field={field} onChange={onChange} />
    } else if (field.type === "listItem") {
        // if (field.require !== null && field.require !== undefined) {
        //     let requiredField = createFields[field.require["key"]];
        //     console.log(requiredField);
        //     if (requiredField !== undefined && requiredField !== null) {
        //         return <ListItem field={field} requireParam={requiredField} value={value} onChange={onChange} />
        //     } else {
        //         return <></>
        //     }
        // } else {
        //     return <ListItem field={field} value={value} onChange={onChange} />
        // }
        return <ListItem field={field} value={value} onChange={onChange} />
    }
    else {
        return (
            <div>
            </div>
        )
    }
}