import React from 'react';

export default function() {
    return (
        <div>
            <p>
                Termly
                PROGRESS
                90
                1.
                Privacy Policy Uses
                2.
                User Information
                3.
                Collection of Information
                4.
                Use of Information
                5.
                Disclosure of Information
                6.
                Use of Tracking Technologies
                7.
                User Rights
                8.
                Final Details
                (800) 242-2985, Mon-Fri, 10AM - 6PM EDT
                Privacy Policy
                SAVE
                PUBLISH...
                Company
                Full legal name of company
                Include corporate ending if applicable: Inc., LLC, etc.

                e.g., Apple Inc.
                Are you doing business under a short form or trade name (also known as a DBA)?

                Yes
                No
                Email
                Include your company email address or your personal email address.

                contact@example.com
                Phone number

                Include your office phone number or your personal phone number.

                e.g., 1-800-555-5555
                Fax number
                e.g., 1-800-555-5555
                BACK
                NEXT
                PRIVACY POLICY
                Last updated April 14, 2023
                Lorem ipsum dolor sit amet, consectetur adipiscing elit. Phasellus vestibulum commodo lorem eget tincidunt. Curabitur imperdiet commodo risus sed commodo.

                Nullam auctor pellentesque pretium. Mauris blandit est sit amet odio tempus, sit amet viverra ipsum finibus. Class aptent taciti sociosqu ad litora torquent per conubia nostra, per inceptos himenaeos. Donec bibendum congue ipsum quis ullamcorper. Ut risus lacus, faucibus sit amet cursus vitae, tristique id sapien.

                Vestibulum leo justo, mollis euismod nunc eget, feugiat vulputate enim.

                Suspendisse consectetur ac odio rhoncus commodo. In hac habitasse platea dictumst. In hac habitasse platea dictumst.

                Curabitur egestas rhoncus ante, a lobortis velit ultrices id. Integer blandit tincidunt luctus. Nam efficitur a sapien et placerat. Ut faucibus quis nulla dignissim mollis.

                PREVIEW
                Privacy Policy
                Terms Of Use
                Disclaimer
                Cookie Policy
                Roadmap
                Consent Preferences

                Close
                PRIVACY NOTICE

                Last updated __________



                This privacy notice for __________ ("Company," "we," "us," or "our"), describes how and why we might collect, store, use, and/or share ("process") your information when you use our services ("Services"), such as when you:
                Visit our website at emtabb.com, or any website of ours that links to this privacy notice
                Engage with us in other related ways, including any sales, marketing, or events
                Questions or concerns? Reading this privacy notice will help you understand your privacy rights and choices. If you do not agree with our policies and practices, please do not use our Services. If you still have any questions or concerns, please contact us at __________.


                SUMMARY OF KEY POINTS

                This summary provides key points from our privacy notice, but you can find out more details about any of these topics by clicking the link following each key point or by using our table of contents below to find the section you are looking for.

                What personal information do we process? When you visit, use, or navigate our Services, we may process personal information depending on how you interact with __________ and the Services, the choices you make, and the products and features you use. Learn more about personal information you disclose to us.

                Do we process any sensitive personal information? We do not process sensitive personal information.

                Do we receive any information from third parties? We do not receive any information from third parties.

                How do we process your information? We process your information to provide, improve, and administer our Services, communicate with you, for security and fraud prevention, and to comply with law. We may also process your information for other purposes with your consent. We process your information only when we have a valid legal reason to do so. Learn more about how we process your information.

                In what situations and with which parties do we share personal information? We may share information in specific situations and with specific third parties. Learn more about when and with whom we share your personal information.

                What are your rights? Depending on where you are located geographically, the applicable privacy law may mean you have certain rights regarding your personal information. Learn more about your privacy rights.

                How do you exercise your rights? The easiest way to exercise your rights is by submitting a data subject access request, or by contacting us. We will consider and act upon any request in accordance with applicable data protection laws.

                Want to learn more about what __________ does with any information we collect? Review the privacy notice in full.


                TABLE OF CONTENTS

                1. WHAT INFORMATION DO WE COLLECT?
                2. HOW DO WE PROCESS YOUR INFORMATION?
                3. WHEN AND WITH WHOM DO WE SHARE YOUR PERSONAL INFORMATION?
                4. DO WE USE COOKIES AND OTHER TRACKING TECHNOLOGIES?
                5. HOW DO WE HANDLE YOUR SOCIAL LOGINS?
                6. HOW LONG DO WE KEEP YOUR INFORMATION?
                7. DO WE COLLECT INFORMATION FROM MINORS?
                8. WHAT ARE YOUR PRIVACY RIGHTS?
                9. CONTROLS FOR DO-NOT-TRACK FEATURES
                10. DO CALIFORNIA RESIDENTS HAVE SPECIFIC PRIVACY RIGHTS?
                11. DO WE MAKE UPDATES TO THIS NOTICE?
                12. HOW CAN YOU CONTACT US ABOUT THIS NOTICE?
                13. HOW CAN YOU REVIEW, UPDATE, OR DELETE THE DATA WE COLLECT FROM YOU?


                1. WHAT INFORMATION DO WE COLLECT?

                Personal information you disclose to us

                In Short: We collect personal information that you provide to us.

                We collect personal information that you voluntarily provide to us when you express an interest in obtaining information about us or our products and Services, when you participate in activities on the Services, or otherwise when you contact us.

                Sensitive Information. We do not process sensitive information.

                Social Media Login Data. We may provide you with the option to register with us using your existing social media account details, like your Facebook, Twitter, or other social media account. If you choose to register in this way, we will collect the information described in the section called "HOW DO WE HANDLE YOUR SOCIAL LOGINS?" below.

                All personal information that you provide to us must be true, complete, and accurate, and you must notify us of any changes to such personal information.

                2. HOW DO WE PROCESS YOUR INFORMATION?

                In Short: We process your information to provide, improve, and administer our Services, communicate with you, for security and fraud prevention, and to comply with law. We may also process your information for other purposes with your consent.

                We process your personal information for a variety of reasons, depending on how you interact with our Services, including:





                3. WHEN AND WITH WHOM DO WE SHARE YOUR PERSONAL INFORMATION?

                In Short: We may share information in specific situations described in this section and/or with the following third parties.

                We may need to share your personal information in the following situations:
                Business Transfers. We may share or transfer your information in connection with, or during negotiations of, any merger, sale of company assets, financing, or acquisition of all or a portion of our business to another company.
                When we use Google Maps Platform APIs. We may share your information with certain Google Maps Platform APIs (e.g., Google Maps API, Places API).

                4. DO WE USE COOKIES AND OTHER TRACKING TECHNOLOGIES?

                In Short: We may use cookies and other tracking technologies to collect and store your information.

                We may use cookies and similar tracking technologies (like web beacons and pixels) to access or store information. Specific information about how we use such technologies and how you can refuse certain cookies is set out in our Cookie Notice.

                5. HOW DO WE HANDLE YOUR SOCIAL LOGINS?

                In Short: If you choose to register or log in to our Services using a social media account, we may have access to certain information about you.

                Our Services offer you the ability to register and log in using your third-party social media account details (like your Facebook or Twitter logins). Where you choose to do this, we will receive certain profile information about you from your social media provider. The profile information we receive may vary depending on the social media provider concerned, but will often include your name, email address, friends list, and profile picture, as well as other information you choose to make public on such a social media platform.

                We will use the information we receive only for the purposes that are described in this privacy notice or that are otherwise made clear to you on the relevant Services. Please note that we do not control, and are not responsible for, other uses of your personal information by your third-party social media provider. We recommend that you review their privacy notice to understand how they collect, use, and share your personal information, and how you can set your privacy preferences on their sites and apps.

                6. HOW LONG DO WE KEEP YOUR INFORMATION?

                In Short: We keep your information for as long as necessary to fulfill the purposes outlined in this privacy notice unless otherwise required by law.

                We will only keep your personal information for as long as it is necessary for the purposes set out in this privacy notice, unless a longer retention period is required or permitted by law (such as tax, accounting, or other legal requirements).

                When we have no ongoing legitimate business need to process your personal information, we will either delete or anonymize such information, or, if this is not possible (for example, because your personal information has been stored in backup archives), then we will securely store your personal information and isolate it from any further processing until deletion is possible.

                7. DO WE COLLECT INFORMATION FROM MINORS?

                In Short: We do not knowingly collect data from or market to children under 18 years of age.

                We do not knowingly solicit data from or market to children under 18 years of age. By using the Services, you represent that you are at least 18 or that you are the parent or guardian of such a minor and consent to such minor dependent’s use of the Services. If we learn that personal information from users less than 18 years of age has been collected, we will deactivate the account and take reasonable measures to promptly delete such data from our records. If you become aware of any data we may have collected from children under age 18, please contact us at __________.

                8. WHAT ARE YOUR PRIVACY RIGHTS?

                In Short:  You may review, change, or terminate your account at any time.

                If you are located in the EEA or UK and you believe we are unlawfully processing your personal information, you also have the right to complain to your Member State data protection authority or UK data protection authority.

                If you are located in Switzerland, you may contact the Federal Data Protection and Information Commissioner.

                Withdrawing your consent: If we are relying on your consent to process your personal information, which may be express and/or implied consent depending on the applicable law, you have the right to withdraw your consent at any time. You can withdraw your consent at any time by contacting us by using the contact details provided in the section "HOW CAN YOU CONTACT US ABOUT THIS NOTICE?" below.

                However, please note that this will not affect the lawfulness of the processing before its withdrawal nor, when applicable law allows, will it affect the processing of your personal information conducted in reliance on lawful processing grounds other than consent.

                9. CONTROLS FOR DO-NOT-TRACK FEATURES

                Most web browsers and some mobile operating systems and mobile applications include a Do-Not-Track ("DNT") feature or setting you can activate to signal your privacy preference not to have data about your online browsing activities monitored and collected. At this stage no uniform technology standard for recognizing and implementing DNT signals has been finalized. As such, we do not currently respond to DNT browser signals or any other mechanism that automatically communicates your choice not to be tracked online. If a standard for online tracking is adopted that we must follow in the future, we will inform you about that practice in a revised version of this privacy notice.

                10. DO CALIFORNIA RESIDENTS HAVE SPECIFIC PRIVACY RIGHTS?

                In Short: Yes, if you are a resident of California, you are granted specific rights regarding access to your personal information.

                California Civil Code Section 1798.83, also known as the "Shine The Light" law, permits our users who are California residents to request and obtain from us, once a year and free of charge, information about categories of personal information (if any) we disclosed to third parties for direct marketing purposes and the names and addresses of all third parties with which we shared personal information in the immediately preceding calendar year. If you are a California resident and would like to make such a request, please submit your request in writing to us using the contact information provided below.

                If you are under 18 years of age, reside in California, and have a registered account with Services, you have the right to request removal of unwanted data that you publicly post on the Services. To request removal of such data, please contact us using the contact information provided below and include the email address associated with your account and a statement that you reside in California. We will make sure the data is not publicly displayed on the Services, but please be aware that the data may not be completely or comprehensively removed from all our systems (e.g., backups, etc.).

                11. DO WE MAKE UPDATES TO THIS NOTICE?

                In Short: Yes, we will update this notice as necessary to stay compliant with relevant laws.

                We may update this privacy notice from time to time. The updated version will be indicated by an updated "Revised" date and the updated version will be effective as soon as it is accessible. If we make material changes to this privacy notice, we may notify you either by prominently posting a notice of such changes or by directly sending you a notification. We encourage you to review this privacy notice frequently to be informed of how we are protecting your information.

                12. HOW CAN YOU CONTACT US ABOUT THIS NOTICE?

                If you have questions or comments about this notice, you may email us at __________ or by post to:

                __________
                __________
                __________

                13. HOW CAN YOU REVIEW, UPDATE, OR DELETE THE DATA WE COLLECT FROM YOU?

                Based on the applicable laws of your country, you may have the right to request access to the personal information we collect from you, change that information, or delete it. To request to review, update, or delete your personal information, please fill out and submit a data subject access request.
            </p>
        </div>
    )
}