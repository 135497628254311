import requests from '../index'
import constants from "../../constants";

const {ACTION_ADD_SEEN_NOTIFICATIONS, ACTION_GET_SEEN_NOTIFICATIONS} = constants;
const { getItem, storeItem } = requests;

const SEEN_NOTIFICATIONS_KEY_ITEM_LOCAL_STORAGE = "SEEN_NOTIFICATIONS_KEY_ITEM_LOCAL_STORAGE";

const notificationRequests = (payload) => {
    let state = [];
    switch (payload.action) {
        case ACTION_GET_SEEN_NOTIFICATIONS    : state = getSeenNotifications(); break;
        case ACTION_ADD_SEEN_NOTIFICATIONS    : state = addSeenNotifications(payload.data); break;
    }
    return state;
}

const getSeenNotifications = () => {
    let items = getItem(SEEN_NOTIFICATIONS_KEY_ITEM_LOCAL_STORAGE);
    if (items === "") {
        items = []
    }
    return items;
}

const addSeenNotifications = (item) => {
    let items = getSeenNotifications();
    items.push(item);
    return storeSeenNotifications(items);
}

const storeSeenNotifications = (items) => {
    storeItem(SEEN_NOTIFICATIONS_KEY_ITEM_LOCAL_STORAGE, items);
    return items;
}

const payloadGetSeenNotifications = () => {
    let payload = {
        action: ACTION_GET_SEEN_NOTIFICATIONS,
        data  : {}
    }
    return notificationRequests(payload);
}
const payloadAddSeenNotifications = (data) => {
    let payload = {
        action: ACTION_ADD_SEEN_NOTIFICATIONS,
        data  : data
    }
    return notificationRequests(payload);
}

export default {
    notificationRequests : notificationRequests,
    payloadAddSeenNotifications : payloadAddSeenNotifications,
    payloadGetSeenNotifications : payloadGetSeenNotifications,
}