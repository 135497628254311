import React, { useState, useEffect } from "react";
import AbstractDataGrid from "../../content/datagrid/AbstractDataGrid";
import requests from "../../requests";
import constants from "../../constants";
import userDataService from "../../services/userDataService";
import LoadingPage from "../LoadingPage";
import Sidebar from "../../components/Sidebar";
import Navbar from "../../components/Navbar";
import {buildTemplateDatagrid} from "../../util/datagrid";


export default (props) => {

    const [loaded, setLoaded] = useState(false);
    const [categoryList, setCategoryList] = useState([]);
    const [fields, setFields] = useState([]);


    useEffect(() => {
        initialDatagrid().then(result => {
            setLoaded(true)
            setCategoryList(result.categoryList);
            setFields(result.fields);
        }).catch();
        return () => {
            setCategoryList([]);
            setFields([]);
            setLoaded(false);
        }
    }, []);

    if (loaded) {
        return (
            <div className="g-sidenav-show  bg-gray-100">
                <Sidebar />
                <div id="content">
                    <Navbar navbarItems={constants.NAVBAR_PRODUCT_ITEMS}/>
                    <div className="container" >
                        <AbstractDataGrid columns={fields} datasource={categoryList} />
                    </div>
                </div>
            </div>
        )
    } else {
        return <LoadingPage />
    }

}


async function initialDatagrid() {
    let access_token = requests.getSessionStorage(constants.ACCESS_TOKEN);
    if ( access_token !== "") {
        requests.setCookie(constants.ACCESS_TOKEN, access_token, constants.LONG_EXPIRY_DATE);
    }

    let userData = await userDataService.loadUserData();
    const SPACE_NAME = userData.space;

    let categoryList = [];

    if (userData) {
        let responseProduct = await requests.getData(`/api/productservice/subcategory/datagrid`, constants.ACCESS_TOKEN);
        categoryList = responseProduct.space;
    }

    // let responseFields = await requests.getData(`/api/space/space-structure/category-master/create-form`, constants.ACCESS_TOKEN);
    // console.log(Object.keys(categoryMasterList.length !== 0 ? categoryMasterList[0] : {}));
    return {
        fields: buildTemplateDatagrid(Object.keys(categoryList.length !== 0 ? categoryList[0] : {})),
        categoryList: categoryList
    }
}