import React, {useState, useEffect} from "react";

import  {Spinner} from "react-bootstrap";

import requests from "../../../requests";
import constants from "../../../constants";


const FacebookReelsSubmit = ({page}) => {

    console.log(page);
    const [status, setStatus] = useState('idle'); // 'idle' | 'loading' | 'success' | 'error'
    const [selectedForm, setSelectedForm] = useState({});

    const handleInputChange = (event) => {
        setSelectedForm({ ...selectedForm, [event.target.name]: event.target.value });
    };

    const handleSubmit = async (pageId, accessToken) => {
        let formSubmit = Object.assign({}, selectedForm);
        formSubmit.page_id = pageId;
        formSubmit.page_token = accessToken;
        setStatus('loading');
        // Thực hiện gọi API ở đây
        let responseSubmit = await submitReelsApi(formSubmit);
        if (responseSubmit.space.message) {
            alert(responseSubmit.space.message);

            // Giả sử API trả về true
            setStatus('success');
        } else {
            // Giả sử API trả về false
            setStatus('error');
        }
    };

    const submitReelsApi = async (form) => {
        try {
            return await requests.postData("/api/v1/meta/upload-reels", form, constants.ACCESS_TOKEN);
        } catch (error) {
            return { message : "error" }
        }
    }

    const renderStatusMessage = () => {
        if (status === 'loading') {
            return <Spinner animation="border" />;
        } else if (status === 'success') {
            return <div className="text-success">Đăng video thành công</div>;
        } else if (status === 'error') {
            return <div className="text-danger">Đăng video thất bại</div>;
        }
        return null;
    };

    if (page) {
        return (
            <div className="card shadow">
                <div className="card-header">{page.name}</div>
                <div className="card-body">
                    <form>
                        <div className="form-group">
                            <label htmlFor="input">Nhập link Tiktok</label>
                            <input
                                type="text"
                                className="form-control"
                                name="video_target"
                                id="video_target"
                                onChange={handleInputChange}
                            />
                        </div>
                        <div className="form-group">
                            <label htmlFor="input">Nhập tiêu đề video</label>
                            <input
                                type="text"
                                className="form-control"
                                name="video_title"
                                id="video_title"
                                onChange={handleInputChange}
                            />
                        </div>
                        <div className="form-group">
                            <label htmlFor="textarea">Mô tả video</label>
                            <textarea
                                className="form-control"
                                name="video_description"
                                id="video_description"
                                onChange={handleInputChange}
                            ></textarea>
                        </div>
                    </form>
                </div>
                <div className="card-footer">
                    <button
                        className="btn btn-primary"
                        disabled={status === 'loading'}
                        onClick={() => handleSubmit(page.id, page.access_token)}
                    >
                        Upload Video
                    </button>
                    {renderStatusMessage()}
                </div>
            </div>
        )
    } else {
        <></>
    }
}

export default FacebookReelsSubmit;