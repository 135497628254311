import React, { useState } from 'react';

import requests from '../requests';
import constants from '../constants';

const LoginPage = (props) => {
    const [loginRequest, setLoginRequest] = useState({});


    const handleChangeUsername = (event) => {
        setLoginRequest(preState => ({
            ...preState,
            username: event.target.value
        }))
    }

    const handleChangePassword = (event) => {
        setLoginRequest(preState => ({
            ...preState,
            password: event.target.value
        }));
    };

    const handleSubmitRequestLoginForm = async () => {
        let responseData = await requests.postData("/api/verify/console/login", loginRequest);
        if (responseData.message === "Invalid authentication") {
            alert("Tài khoản hoặc mật khẩu không chính xác");
            return;
        }
        if (responseData.access_token) {
            requests.setSessionStorage(constants.ACCESS_TOKEN, responseData[constants.ACCESS_TOKEN]);
            requests.setSessionStorage(constants.USER_SSO, responseData[constants.USER_SSO]);
        }
        window.location.href = "/";
    }

    return (
        <div className="" style={{ backgroundColor: "#7386D5",  height: "1000px" }}>
            <div className="container">
                <div className="row d-flex justify-content-center">
                    <div className="col-12 col-md-8 mt-5" style={{marginTop : "20rem"}}>
                        <div className="card border-info shadow p-5" style={{minWidth: "15rem", width : "100%", maxWidth:"25rem"}}>
                            <div className="card-header btn-outline-info">
                                <h2><strong>ESPACE CONSOLE MANAGER</strong></h2>
                               <p> DỊCH VỤ ĐIỆN TOÁN ĐÁM MÂY DÀNH CHO THƯƠNG MẠI ĐIỆN TỬ.</p>
                            </div>
                            <div className="card-body">
                                <h5 className="card-title">Vui lòng đăng nhập tài khoản</h5>
                                <div className="form-group">
                                    <label for="inputUsername">Điền tài khoản</label>
                                    <input type="text" className="form-control" id="inputUsername"
                                        onChange={handleChangeUsername}
                                        placeholder="Điền tài khoản" />
                                </div>
                                <div className="form-group">
                                    <label for="inputPassword">Điền mật khẩu</label>
                                    <input type="password" className="form-control" id="inputPassword"
                                        onChange={handleChangePassword}
                                        placeholder="Password" />
                                </div>

                                <button type="submit" className="btn btn-primary" onClick={handleSubmitRequestLoginForm}>Đăng nhập</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default LoginPage;