import React from "react";

import Navbar from '../components/Navbar';
import Sidebar from '../components/Sidebar';
import ProductContent from '../content/ProductContent';
// import axios from 'axios';
import requests from "../requests";
import constants from "../constants";
import userDataService from "../services/userDataService";

// const DOMAIN = process.env.DOMAIN;

class ProductsPage extends React.Component {
    constructor(props) {
        super(props);
        this.category = props.match.params.category;
        this.state = {
            products: []
        }
    }

    async componentWillMount() {
        let access_token = requests.getSessionStorage(constants.ACCESS_TOKEN);
        if ( access_token !== "") {
            requests.setCookie(constants.ACCESS_TOKEN, access_token, constants.LONG_EXPIRY_DATE);
        }

        let userData = await userDataService.loadUserData();
        const SPACE_NAME = userData.space;

        let api = `/api/productservice/product/loadspace?space=${SPACE_NAME}&pageable=createdDate,-1,1,1000`;
        if (this.category) {
            api = `/api/workflow/product-listing?category=${this.category}&pageable=release_date,-1,1,1000`;
        }
        let responseData = await requests.getData(api, constants.ACCESS_TOKEN);
        if (responseData.space) {
            this.setState({
                products: responseData.space
            })
        }
    }

    render() {
        return (
            <div className="g-sidenav-show  bg-gray-100">
                <Sidebar />
                <div id="content">
                    <Navbar navbarItems={constants.NAVBAR_PRODUCT_ITEMS}/>
                    <div className="container" >
                            {this.state.products.length !== 0 ? (
                                <ProductContent products={this.state.products} />
                            ) :
                                (<div class="row">
                                    <p>Không có sản phẩm</p>
                                </div>)
                            }
                    </div>
                </div>
            </div>
        )
    }
}

export default ProductsPage;