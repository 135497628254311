import React, {useState, useMemo, useEffect} from "react";
import util from "../../util/util";
import SimpleMdeReact from "react-simplemde-editor";
import LoadingPage from "../../page/LoadingPage";

function initEditorValue(value, loadingFunction) {
    let content = util.notNull(value) ? value : "";
    // let content = util.notNull(propertiesValue.content) ? propertiesValue.content : [];
    loadingFunction(false);
    return content;
}

const Editor = (props) => {
    let field = props.field;
    let onChange = props.onChange;

    const [loading, setLoading] = useState(true);
    const [value, setValue] = useState("");

    function onChangeEditor(value) {
        let event = {};
        event.preventDefault = () => {};
        event["target"] = {
            id : field.key,
            value : value
        };
        onChange(event);
        setValue(value);
    }

    const autofocusNoSpellcheckerOptions = useMemo(() => {
        return {
            autofocus: false,
            spellChecker: false,
        };
    }, []);

    useEffect(() => {
        setValue(initEditorValue(props.value, setLoading));

        return () => {
            setValue("");
            setLoading(true);
        }
    }, []);

    if (!loading) {
        return (
            <div className="col-xs-12 col-12 col-md-12 mt-3 p-1">
                <SimpleMdeReact
                    options={autofocusNoSpellcheckerOptions}
                    id={field.key} value={value} onChange={onChangeEditor} />
            </div>
        );
    } else {
        return <LoadingPage />
    }
}

export default Editor;