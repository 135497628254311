import React from "react";

import MainContent from '../content/MainContent';
import Navbar from '../components/Navbar';
import LoginPage from "./LoginPage";
import Sidebar from '../components/Sidebar';
import requests from '../requests';
import constants from "../constants";

class HomePage extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            access_token: null
        }
    }

    async componentWillMount() {
        let access_token = requests.getSessionStorage(constants.ACCESS_TOKEN);
        if ( access_token !== "") {
            requests.setCookie(constants.ACCESS_TOKEN, access_token, constants.LONG_EXPIRY_DATE);
        }
        if (access_token) {
            this.setState({
                access_token : access_token
            })
        } else {
            this.setState({
                access_token : null
            });
        }
        console.log(await this.testDelevelop());
    }

    async testDelevelop() {
        // const testUrl = "http://localhost:8080/api/product-statistic/reduce/count-in-stock/BAOTRANSTORE_4";
        // let data = await requests.postData(testUrl, {}, constants.ACCESS_TOKEN);
        // return data;
    }

    render() {
        return (
            <div className="g-sidenav-show  bg-gray-100">
                { this.state.access_token ? (
                    <div>
                    <Sidebar />
                    <div id="content">
                        <Navbar />
                        <MainContent /> 
                    </div>
                    </div>
                ): <LoginPage />}
            </div>
        )
    }
}

export default HomePage;