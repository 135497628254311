import React, {useState} from 'react';

import { Card, Button } from 'react-bootstrap';
import requests from "../requests";

import util from "../util/util";
import constants from '../constants';

let borderRadius = "0.7rem";
let cardSubStyleSheet = {
    hover: "",
    background: "white",
    borderRadius: "2"
}

let cardImageSubStyleSheet = {
    width: "100%",
    borderTopLeftRadius: borderRadius,
    borderTopRightRadius: borderRadius
}

export default class OutboxDetail extends React.Component {
    constructor(props) {
        super(props);
        this.outboxes = props.outboxes;
        console.log(this.outboxes);
    }

    render() {
        const outboxes = this.outboxes;
        const outboxDetail = outboxes[0];
        const totalPrice = outboxes.reduce((previousValue, currentValue) => previousValue + currentValue.purchase * currentValue.promotion, 0);
        return (
            <div className="col-xs-12 col-12 col-md-9 col-lg-9 mt-4">
                <div className="container-fluid">
                    <div className="row">
                        <div class="col-12 col-md-6">
                            {outboxes.map(outbox => (
                                <Card className="box shadow w-100 p-4 mb-2" style={cardSubStyleSheet}>
                                    <Card.Body>
                                        <Card.Title><h5><strong>{outbox.label}</strong></h5></Card.Title>
                                        <div className="line"></div>
                                        <Card.Subtitle>Số lượng: {outbox.purchase}</Card.Subtitle>
                                        <Card.Text>Giá tiền: {util.beautyNumber(outbox.promotion)} x {outbox.purchase} = {util.beautyNumber(outbox.promotion * outbox.purchase)}</Card.Text>
                                    </Card.Body>
                                </Card>
                            ))
                            }
                        </div>
                        <div className="col-xs-12 col-12 col-md-6">
                            <Card className="p-3 shadow w-100 mb-3" >
                                <h1 className="mb-5"><span class="badge badge-warning text-white w-100"> Hóa đơn </span></h1>
                                <div>
                                    <div class="form-group">
                                        <label>Địa chỉ giao hàng: </label>
                                        <p type="text" class="form-control"> {outboxDetail.location} </p>
                                    </div>

                                    <label>Số điện thoại: </label>
                                    <div className="input-group mb-3">
                                        <a className="btn btn-success w-100" type="text" href={"tel:" + outboxDetail.user_sso}>Gọi ngay: {outboxDetail.user_sso}</a>
                                    </div>
                                </div>
                                <div className="line"></div>
                                <div className="input-group mt-4 mb-4">
                                    <div className="input-group-prepend">
                                        <span className="input-group-text">Thành Tiền: </span>
                                    </div>
                                    <input className="form-control" type="text" value={util.beautyNumber(totalPrice)} readOnly />
                                    <div class="input-group-append">
                                        <span class="input-group-text"> đ</span>
                                    </div>
                                </div>

                                <ChangeStatusOutboxButton outbox_code={outboxDetail.outbox_code} status={outboxDetail.status} />
                                <CancelOutboxButton outbox_code={outboxDetail.outbox_code} />

                                <div className="line"></div>
                            </Card>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

function ChangeStatusOutboxButton(props) {

    const { outbox_code, status } = props;

    async function approveOutbox() {
        let responseData = await requests.postData("/api/outbox/status", { outbox_code: outbox_code, status: constants.APPROVED }, constants.ACCESS_TOKEN);
        alert(responseData.message);
        window.location.href = "/outboxes"
    }
    async function completedOutbox() {
        let responseData = await requests.postData("/api/outbox/status", { outbox_code: outbox_code, status: constants.COMPLETED }, constants.ACCESS_TOKEN);
        alert(responseData.message);
        window.location.href = "/outboxes"
    }
    async function deliveryOutbox() {
        let responseData = await requests.postData("/api/outbox/status", { outbox_code: outbox_code, status: constants.DELIVERING }, constants.ACCESS_TOKEN);
        alert(responseData.message);
        window.location.href = "/outboxes"
    }

    switch (status) {
        case constants.WAITING: return (<Button className="m-2" variant="outline-success" onClick={approveOutbox}>Chấp nhận đơn hàng</Button>);
        case constants.APPROVED: return (<Button className="m-2" variant="outline-info" onClick={deliveryOutbox}>Giao hàng</Button>);
        case constants.DELIVERING: return (<Button className="m-2" variant="primary" onClick={completedOutbox}>Hoàn tất đơn hàng</Button>);
        default: return (<Button className="m-2" variant="secondary">Đang tải</Button>);
    }
}

function CancelOutboxButton(props) {
    const { outbox_code } = props;
    const [reason, setReason] = useState("");

    async function approveOutbox() {
        let responseData = await requests.postData("/api/outbox/status", { outbox_code: outbox_code, status: "CANCELLED", reason: reason }, constants.ACCESS_TOKEN);
        alert(responseData.message);
        window.location.href = "/outboxes"
    }

    return (
        <div className="w-100 mt-3 form-group">
            <label>Nguyên nhân hủy</label>
            <textarea className="form-control m-2" value={reason} onChange={(event) => { setReason(event.target.value) }}></textarea>
            <Button variant="outline-danger m-2" onClick={approveOutbox}>Hủy đơn hàng</Button>
        </div>
    )
}