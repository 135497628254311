import React, {useState, useEffect} from 'react';

import Navbar from '../components/Navbar';
import Sidebar from '../components/Sidebar';
import requests from '../requests';
import constants from '../constants';
import util from '../util/util';
import userDataService from '../services/userDataService';
import AbstractUpdateForm from "../content/createform/AbstractUpdateForm";
import AbstractCreateForm from "../content/createform/AbstractCreateForm";
import LoadingPage from "./LoadingPage";

// export default class CreateProductForm extends React.Component {
//     constructor(props) {
//         super(props);
//         this.product_code = util.parseQuery(props.location.search).product_code;
//         this.SPACE_NAME = "";
//         this.state = {
//             fields : [],
//             product: null
//         }
//     }
//
//     async componentWillMount() {
//         let access_token = requests.getSessionStorage(constants.ACCESS_TOKEN);
//         if ( access_token !== "") {
//             requests.setCookie(constants.ACCESS_TOKEN, access_token, constants.LONG_EXPIRY_DATE);
//         }
//
//         let userData = await userDataService.loadUserData();
//         this.SPACE_NAME = userData.space;
//
//         if (util.notNull(this.product_code)) {
//             let responseProduct = await requests.getData(`/api/loadspace/product?space=${this.SPACE_NAME}&product_code=${this.product_code}`)
//             this.setState({
//                 product: responseProduct.space[0]
//             });
//         }
//         let responseFields = await requests.getData(`/api/space/space-structure/product/create-form`, constants.ACCESS_TOKEN)
//         this.setState({
//             fields: responseFields.createForm
//         });
//     }
//
//     render() {
//         return (
//             <div className="wrapper">
//                 <Sidebar />
//                 <div id="content" className="container-fluid">
//                     <Navbar />
//                     { loading ?
//                         (this.product_code ?
//                             (<AbstractUpdateForm
//                                 fields={this.state.fields} product={this.state.product}
//                                 updateApi={"/api/productservice/loadspace/product/update"} deleteApi={""} redirectLocation={""}
//                             />) :
//                             (<AbstractCreateForm
//                                 fields={this.state.fields} createApi={"/api/productservice/loadspace/product/create"} redirectLocation={"/products"}
//                             />))
//                         : <LoadingPage />
//                     }
//                     {/*{this.state.fields.length !== 0 ? (<CreateForm fields={this.state.fields} product={this.state.product} />) : (<div></div>)}*/}
//                 </div>
//             </div>
//         )
//     }
// }

// import requests from "../../requests";
// import constants from "../../constants";
// import userDataService from "../../services/userDataService";
// import util from "../../util/util";
// import React, {useEffect, useState} from "react";
// import Sidebar from "../../components/Sidebar";
// import Navbar from "../../components/Navbar";
// import LoadingPage from "../../page/LoadingPage";
// import AbstractUpdateForm from "../createform/AbstractUpdateForm";
// import AbstractCreateForm from "../createform/AbstractCreateForm";

export default function CreateProductForm (props) {
    const product_code = util.parseQuery(props.location.search).product_code;
    const [loading, setLoading] = useState(false);
    const [fields, setFields] = useState([]);
    const [product, setProduct] = useState({});

    useEffect(()=> {
        initialCreateForm(product_code).then(result => {
            setFields(result.fields);
            setProduct(result.variant_price);
            setLoading(true);
        })
        return () => {
            setFields([]);
            setProduct({});
            setLoading(false);
        }
    }, []);

    if (Array.isArray(fields) && fields.length) {
        return (
            <div className="wrapper">
                <Sidebar />
                <div id="content" className="container-fluid">
                    <Navbar navbarItems={constants.NAVBAR_PRODUCT_ITEMS}/>
                    { loading ?
                        (product_code ?
                            (<AbstractUpdateForm
                                fields={fields} product={product}
                                updateApi={"/api/productservice/product/update"} deleteApi={""} redirectLocation={"/products"}
                            />) :
                            (<AbstractCreateForm
                                fields={fields} createApi={"/api/productservice/product/create"} redirectLocation={"/products"}
                            />))
                        : <LoadingPage />
                    }
                </div>
            </div>
        )
    } else {
        return (
            <div className="wrapper">
                <Sidebar />
                <div id="content" className="container-fluid">
                    <h4>Lỗi tải trang.</h4>
                </div>
            </div>
        )
    }
}


async function initialCreateForm(product_code) {
    let access_token = requests.getSessionStorage(constants.ACCESS_TOKEN);
    if ( access_token !== "") {
        requests.setCookie(constants.ACCESS_TOKEN, access_token, constants.LONG_EXPIRY_DATE);
    }

    let userData = await userDataService.loadUserData();
    const SPACE_NAME = userData.space;

    let variant_price = null;
    if (util.notNull(product_code)) {
        let responseProduct = await requests.getData(`/api/productservice/product/mono-product?space=${SPACE_NAME}&product_code=${product_code}`, constants.ACCESS_TOKEN);
        variant_price = responseProduct.space;
    }
    let responseFields = await requests.getData(`/api/space/space-structure/product/create-form`, constants.ACCESS_TOKEN);
    return {
        fields: responseFields.createForm,
        variant_price: variant_price
    }
}