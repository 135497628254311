import React, { useState, useEffect } from 'react';
import notification from '../requests/notificationRequests';
import requests from '../requests';
import constants from '../constants';
import ReactAudioPlayer from 'react-audio-player';
import LoadingPage from '../page/LoadingPage';

const MainContent = () => {

    const [loaded, setLoaded] = useState(false);
    const [orders, setOrders] = useState([]);
    const [ordersNotSeen, setOrdersNotSeen] = useState([]);

    async function initialOrders() {
        const noticed = await notification.payloadGetSeenNotifications();
        setOrders(noticed);
        let responseData = await requests.getData(`/api/workflow/step?step=STORE_OWNER_APPROVE_REQUEST&status=WAITING`, constants.ACCESS_TOKEN);
        if (responseData.message !== "Missing Authorization Header") {
            let ordersWaiting = responseData.space;
            if (ordersWaiting.length === 0) {
                return;
            }
            let ordersWaitingNotSeen = await ordersWaiting.filter(order => !noticed.some(noti => noti.outbox_code === order.outbox_code));
            setOrdersNotSeen(ordersWaitingNotSeen);
        }
        else {
            setOrdersNotSeen([]);
        }
    }

    function refreshNotify() {
        setLoaded(false);
        initialOrders().then(result => {
            setLoaded(true);
        });
    }

    useEffect(() => {
        initialOrders().then(result => {
            setLoaded(true);
        });
        
        const id = setInterval(()=> {
            refreshNotify();
        }, 1000 * 60);

        return () => {
            setLoaded(false);
            setOrders([]);
            setOrdersNotSeen([]);
            clearInterval(id);
        }
    }, []);

    if (loaded) {
        return (
            <div>
                {
                    ordersNotSeen.length !== 0 ? (
                        ordersNotSeen.map(order => {
                            return (
                                <div class="alert alert-info alert-dismissible fade show" role="alert">
                                    Hệ thống ghi nhận có đơn hàng mới:
                                    <a href={"/outbox/detail/".concat(order.outbox_code)} className="ml-1 mr-1 btn btn-outline-primary"> {order.outbox_code} </a>
                                    <span>vào <strong>{TimeSince(new Date(order.created_date))}</strong> </span>
                                    <button type="button" className="close"
                                        onClick={() => {
                                            setOrders(notification.payloadAddSeenNotifications(order))
                                        }}
                                        data-dismiss="alert" aria-label="Close">
                                        <span aria-hidden="true">&times;</span>
                                    </button>
                                    <ReactAudioPlayer
                                        src="/notification.mp3"
                                        autoPlay
                                    />
                                </div>
                            )
                        })      
                    ) : (<div>Hiện chưa có đơn hàng mới</div>)
                }
            </div>
        )
    } else {
        return <LoadingPage />
    }
}

function TimeSince(date) {

    let seconds = Math.floor((new Date() - date) / 1000);

    let interval = seconds / 31536000;

    if (interval > 1) {
        return date.toDateString();
    }
    interval = seconds / 2592000;
    if (interval > 1) {
        return Math.floor(interval) + " tháng trước";
    }
    interval = seconds / 86400;
    if (interval > 1) {
        return Math.floor(interval) + " ngày trước";
    }
    interval = seconds / 3600;
    if (interval > 1) {
        return Math.floor(interval) + " giờ trước";
    }
    interval = seconds / 60;
    if (interval > 1) {
        return Math.floor(interval) + " phút trước";
    }
    return Math.floor(seconds) + " giây trước";
}

export default MainContent;