import requests from "../requests";
import constants from "../constants";

function initialAccessToken() {
    let access_token = requests.getSessionStorage(constants.ACCESS_TOKEN);
    if (access_token !== "") {
        requests.setCookie(constants.ACCESS_TOKEN, access_token, constants.LONG_EXPIRY_DATE);
    }
}
export default {
    initialAccessToken : initialAccessToken
}