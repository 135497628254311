import React, { useState, useEffect } from 'react';
import emptyImage from '../../add-image-empty.png';

import util from "../../util/util";
import requests from '../../requests';
import constants from '../../constants';

class Selected extends React.Component {
    constructor(props) {
        super(props);
        let value = util.notNull(props.value) && Array.isArray(props.value) ? props.value[0] : "";
        this.DOMAIN = props.domain;
        this.field = props.field;
        this.onChange = props.onChange;

        this.state = {
            image: value,
            fileRequest: ""
        }
        this.handleChange = this.handleChange.bind(this);
        this.handlePostBlob = this.handlePostBlob.bind(this);
        this.handleRemoveFilesRequest = this.handleRemoveFilesRequest.bind(this);
    }

    async handleChange(event) {
        const files = event.target.files;
        let fileRequest = {};
        fileRequest.name = files[0].name;
        fileRequest.type = files[0].type;
        fileRequest.base64 = await getBase64(files[0]);

        this.setState({
            image: URL.createObjectURL(event.target.files[0]),
            fileRequest: fileRequest
        });
    }

    async handlePostBlob(event) {
        if (this.state.filesRequest !== "") {
            let responseData = await requests.postData(`/blob`, { "files": [this.state.fileRequest] }, constants.ACCESS_TOKEN);
            if (responseData.code === "200") {
                let key = this.field.key;
                let data = responseData.space[0][this.field["auto_populate"].key];
                event.target.id = key;
                event.target.value = data;
                this.onChange(event);
                alert("Lưu hình ảnh lên mây thành công");
            } else {
                alert("Lưu hình ảnh lên mây thất bại!");
            }
        }
    }

    async handleRemoveFilesRequest() {
        this.setState({
            image: "",
            fileRequest: ""
        })
    }

    render() {
        let image = util.notNull(this.state.image) ? this.state.image : "";
        if (this.field === null) {
            return (
                <div className="container text-center" style={{alignContent: 'center'}}>Lỗi hiển thị
                </div>
            )
        } else {
            return (
                <div className="container text-center" style={{alignContent: 'center'}}>
                    <div className="fileinput-new thumbnail img-raised" style={{height: '25rem'}}>
                        <img style={{width: "100%", height: "100%", objectFit: "contain"}}
                             src={image === "" ? emptyImage : (
                                 image.includes(this.DOMAIN) ? image : `/blob/`.concat(image))}
                        />
                    </div>
                    <div>
                        <button className="btn btn-light container mt-3">
                            <label className="fileinput-new mb-3 col-12">{this.field.label}</label>
                            <input className="mb-3 col-12 col-md-8" type="file" id="selectedFile" name="file"
                                   onChange={this.handleChange}/>
                            <button className="btn btn-info btn-round col-12 col-md-6" onClick={this.handlePostBlob}><i
                                class="bi bi-cloud-arrow-up"></i> Lưu Ảnh Hình Ảnh
                            </button>
                            <button className="btn btn-danger btn-round col-12 col-md-6"
                                    onClick={this.handleRemoveFilesRequest}><i className="fa fa-times"></i> Xóa Ảnh Đã
                                Chọn
                            </button>
                        </button>
                    </div>
                </div>
            )
        }
    }
}

function getBase64(file) {
    return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => {
            let encoded = reader.result.toString().replace(/^data:(.*,)?/, '');
            if ((encoded.length % 4) > 0) {
                encoded += '='.repeat(4 - (encoded.length % 4));
            }
            resolve(encoded);
        };
        reader.onerror = error => reject(error);
    });
}

export default Selected