import React, { useState, useEffect } from 'react';
import { Card, ListGroup, ListGroupItem, Button, Form } from 'react-bootstrap';
import requests from '../requests';
import constants from "../constants"

import util from "../util/util";

import cartRequest from "../requests/cartRequests";
import userRequest from "../requests/userRequests";

const { ACCTION_ADD_TO_CART, ACTION_REMOVE_FROM_CART, ACTION_CLEAR_CART, ACTION_GET_CART, ACTION_UPDATE_ITEM_CART } = constants;
const { ACTION_GET_USER_INFORMATION, ACTION_SET_USER_INFORMATION, ACTION_UPDATE_USER_INFORMATION, ACTION_DELETE_USER_INFORMATION, ACCESS_TOKEN } = constants;

function getUser() {
    let payload = {
        action: ACTION_GET_USER_INFORMATION,
        data  : {}
    };
    return userRequest(payload);
}

function setUser(userData) {
    console.log(userData);
    let payload = {
        action: ACTION_SET_USER_INFORMATION,
        data  : userData
    }
    return userRequest(payload);
}

const CartContent = (props) => {

    function getCart() {
        let payload = {
            action: ACTION_GET_CART,
            data: []
        }
        return cartRequest(payload)
    }

    const [cartData, setCartData] = useState([]);
    const [chooseOption, setChooseOption] = useState(false);

    useEffect(() => {
        setCartData(getCart());
        setChooseOption(false);

        return () => {
            setCartData([]);
            setChooseOption();
        }
    }, [])

    const CHOOSE_ALL_TEXT_INFO = "Chọn tất cả";
    const CANCEL_ALL_TEXT_INFO = "Hủy chọn tất cả";

    function clearCart() {
        let payload = {
            action: ACTION_CLEAR_CART,
            data: []
        }
        setCartData(cartRequest(payload));
    }

    function chooseAll() {
        cartData.forEach(product => {
            product.selected = true;
            let payload = {
                action: ACTION_UPDATE_ITEM_CART,
                data: product
            }
            setCartData(cartRequest(payload));
        });
    }

    function cancelChooseAll() {
        cartData.forEach(product => {
            product.selected = false;
            let payload = {
                action: ACTION_UPDATE_ITEM_CART,
                data: product
            }
            setCartData(cartRequest(payload));
        });
    }

    async function sendRequest() {
        let cart = getCart();
        console.log(cart);
        let user = getUser();
        console.log(user);
        let objectCart = {
        }
        if (user.user_sso === undefined) {
            alert("Chưa cập nhật số điện thoại")
        } else if (user.location === undefined) {
            alert("Chưa nhập địa chỉ giao hàng")
        }

        let cartSelected = cart.filter(product => product.selected);
        objectCart.outboxes = cartSelected.map(product => {
            product.location = user.location;
            product.user_sso = user.user_sso;
            return product;
        });

        function removeFromCart(product) {
            let payload = {
                action: ACTION_REMOVE_FROM_CART,
                data: product
            }
            return cartRequest(payload);
        }

        console.log(objectCart);

        let response = await requests.postData("/api/workflow/graph", objectCart, ACCESS_TOKEN);
        if (response.message === "SUCCESS") {
            for (let i = 0; i < cartSelected.length; i++) {
                setCartData(removeFromCart(cartSelected[i]));
            }
        }
        alert("Đặt hàng thành công");
    }


    if (cartData.length === 0 || !Array.isArray(cartData)) {
        return (
            <div className="row">
                <Card className="col-xs-12 col-12 col-md-8 p-3" style={{ width: '18rem', minHeight: '18rem' }}>
                    Không có sản phẩm trong giỏ hàng
                </Card>
            </div>
        )
    } else {
        return (
            <div className="row">
                <div className="col-12 p-4 bg-white border border-radius justify-content-center mb-3 container-fluid">
                    <div class="row">
                        {
                            chooseOption ? (
                                <div className="col-3">
                                    <Button className="ml-3 mr-3 w-100" onClick={cancelChooseAll} variant="secondary">{CANCEL_ALL_TEXT_INFO}</Button>
                                </div>

                            ) : (
                                    <div className="col-3">
                                        <Button className="ml-3 mr-3 w-100" onClick={chooseAll} variant="info"> {CHOOSE_ALL_TEXT_INFO} </Button>
                                    </div>
                                )
                        }
                        <div className="col-3">
                            <Button className="ml-3 mr-3 w-100" onClick={clearCart} variant="danger">Xóa toàn bộ.</Button>
                        </div>
                    </div>
                </div>

                <div className="col-xs-12 col-12 col-md-6">
                    <h1 className="w-100"><span class="badge badge-secondary w-100">Thông tin đơn hàng</span></h1>
                    {cartData.map(product =>
                        <CartItem key={product.index} actionCart={setCartData} product={product} />
                    )}
                </div>

                <RightNavigation cartData={cartData} sendRequest={sendRequest} />

            </div>
        )
    }
}

const RightNavigation = (props) => {
    const { cartData, sendRequest } = props;
    let totalPrice = 0;

    function calculate() {
        let totalCalculate = 0;
        cartData.forEach(product => {
            if (product.selected) {
                totalCalculate += product.promotion * product.purchase;
            }
        })
        return totalCalculate;
    }
    totalPrice = calculate();

    const [userInformation, setUserInformation] = useState({})


    useEffect( () => {
        setUserInformation(getUser());

        return () => {
            setUserInformation({});
        }
    }, []);

    return (
        <div className="col-xs-12 col-12 col-md-6">
            <Card className="p-3 shadow w-100 mb-3" style={{ width: '18rem' }}>
            <h1 className="mb-5"><span class="badge badge-warning text-white w-100"> Thông tin khách hàng </span></h1>
                <Location userInformation={userInformation} setUserInformation={setUserInformation} />
                <div className="line"></div>
                <div className="input-group mt-4 mb-4">
                    <div className="input-group-prepend">
                        <span className="input-group-text">Thành Tiền: </span>
                    </div>
                    <input className="form-control" type="text" value={util.beautyNumber(totalPrice)} readOnly />
                    <div class="input-group-append">
                        <span class="input-group-text"> đ</span>
                    </div>
                </div>
                <div className="w-100">
                    <Button className="w-100" onClick={sendRequest} variant="success"><h3> Tạo đơn hàng </h3></Button>
                </div>
                <div className="line"></div>
            </Card>
        </div>
    )
}

const Location = (props) => {

    const {userInformation, setUserInformation} = props;

    function onChangeLocation(event) {
        let value = event.target.value;
        let userInformation = getUser();
        console.log(userInformation);
        userInformation.location = value;
        console.log(userInformation);
        setUserInformation(setUser(userInformation));
    }

    function onChangePhoneNumber(event) {
        let value = event.target.value;
        let userInformation = getUser();
        userInformation.user_sso = value;
        console.log(userInformation);
        setUserInformation(setUser(userInformation));
    }

    return (
        <form>
            <div class="form-group">
                <label>Địa chỉ giao hàng</label>
                <input type="text" class="form-control" value={userInformation.location} onChange={onChangeLocation} placeholder="Vui lòng nhập địa chỉ giao hàng" />
            </div>

            <label>Số điện thoại</label>
            <div className="input-group mb-3">
                <div className="input-group-prepend">
                    <span className="input-group-text">+84</span>
                </div>
                <input className="form-control" type="text" value={userInformation.user_sso} onChange={onChangePhoneNumber} placeholder="Vui lòng nhập số điện thoại liên lạc" />
            </div>
        </form>
    )
}

const CartItem = (props) => {
    const { product, actionCart } = props;

    if (product.selected === undefined) {
        product.selected = false;
    }

    function removeProductFromCart() {
        let payload = {
            action: ACTION_REMOVE_FROM_CART,
            data: product
        }
        actionCart(cartRequest(payload));
    }

    function updateProductPurchase(event) {
        let value = parseInt(event.target.value);
        if (value < 0) {
            product.purchase = 0;
        } else {
            product.purchase = value;
        }
        let payload = {
            action: ACTION_UPDATE_ITEM_CART,
            data: product
        }
        actionCart(cartRequest(payload));
    }

    function updateAddToPayment(event) {
        product.selected = event.target.checked;
        let payload = {
            action: ACTION_UPDATE_ITEM_CART,
            data: product
        }
        actionCart(cartRequest(payload));
    }

    return (
        <Card className="p-3 shadow w-100 mb-3" style={{ width: '18rem' }}>

            <div className="form-check mb-3 w-100">
                <input className="form-check-input"
                    style={{ width: "1.6rem", height: "1.6rem" }}
                    type="checkbox" checked={product.selected} onClick={updateAddToPayment} />
                <label className="form-check-label" for="autoSizingCheck"></label>
            </div>

            <Card.Img variant="top" src={`/blob/${product.background}`} />
            <Card.Body>
                <Card.Title>{product.label}</Card.Title>
            </Card.Body>
            <ListGroup className="list-group-flush">
                <ListGroupItem>
                    <div className="input-group mb-1 mt-1">
                        <div className="input-group-prepend">
                            <span className="input-group-text">Số lượng:</span>
                        </div>
                        <input type="number" onChange={updateProductPurchase} value={product.purchase} className="form-control" />
                    </div>
                </ListGroupItem>
                <ListGroupItem>
                    <div className="input-group mb-1 mt-1">
                        <div className="input-group-prepend">
                            <span className="input-group-text">Giá tiền: </span>
                        </div>
                        <input className="form-control" type="text" onChange={updateProductPurchase} value={util.beautyNumber(product.promotion * product.purchase)} readOnly />
                        <div class="input-group-append">
                            <span class="input-group-text"> đ</span>
                        </div>
                    </div>
                </ListGroupItem>
                <ListGroupItem>
                    <div class="form-row align-items-center mt-2">
                        <div className="col-auto">
                            <Button className="teal-500" variant="outline-danger" onClick={removeProductFromCart}>Xóa Khỏi Giỏ Hàng</Button>
                        </div>
                    </div>
                </ListGroupItem>
            </ListGroup>
        </Card>
    )
}

export default CartContent;