import React from 'react';
import requests from "../requests";
import constants from "../constants";

// Icon Library https://icons.getbootstrap.com/

const dataSidebar = [{
    href: "/",
    icon: "dashboard",
    label: "Bảng điều khiển"
},
{
    href: "/outboxes",
    icon: "local_shipping",
    label: "Đơn hàng"
},
{
    href: "/products",
    icon: "content_paste",
    label: "Danh mục sản phẩm"
},
{
    href: "/create-form",
    icon: "bi bi-file-earmark-plus fa-lg",
    label: "Thêm sản phẩm"
},
{
    href: "/library",
    icon: "bi bi-card-image fa-lg",
    label: "Thư viện hình ảnh"
},
{
    href: "/news",
    icon: "bi bi-newspaper fa-lg",
    label: "Tin tức"
},
{
    href: "/partner/meta",
    icon: "bi bi-newspaper fa-lg",
    label: "Meta"
},
]
export default class Sidebar extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            dataSidebar : []
        }
    }

    async componentWillMount() {
        let access_token = requests.getSessionStorage(constants.ACCESS_TOKEN);
        if ( access_token !== "") {
            requests.setCookie(constants.ACCESS_TOKEN, access_token, constants.LONG_EXPIRY_DATE);
        }
        let api = `/api/v1/role/functions/get`;
        let responseData = await requests.getData(api, constants.ACCESS_TOKEN);
        if (responseData.space) {
            this.setState({
                sideBarData: responseData.space
            })
        }
    }

    render() {
        return (
            <nav id="sidebar">
                <div className="sidebar-header">
                    <h3>ESPACE MANAGER</h3>
                </div>

                <ul className="list-unstyled components">
                    {
                        Array.isArray(this.state.sideBarData) ?
                         (
                            this.state.sideBarData.map(item => (
                                <li key={item.DASHBOARD} className="">
                                    {/*<i className={item.icon} />*/}
                                    <a className="m-1" style={window.location.pathname === item.href ?
                                    {background : "#fff", color: "#7386D5"} : {}}
                                    href={item.ref}>{item.product_desc_lvl_3} <i className={"m-1" + item.icon} /></a>
                                </li>
                            ))
                        ) :
                        (
                            <li className="">
                                Không có chức năng
                            </li>
                        )
                    }
                </ul>
            </nav>
        )
    }
}