import React from "react";

import Navbar from '../components/Navbar';
import Sidebar from '../components/Sidebar';

import FacebookSDKComponent from "../components/partner/meta/FacebookSDKComponent";
import FacebookLoginComponent from "../components/partner/meta/FacebookLoginComponent";
import requests from "../requests";
import constants from "../constants";


class FacebookPage extends React.Component {
    constructor(props) {
        super(props);
        // this.search_title = props.match.params.search_title;
        this.appConfig = null

        this.state = {
            pages: []
        }
    }

    // async componentWillMount() {
    //     let access_token = requests.getSessionStorage(constants.ACCESS_TOKEN);
    //     if ( access_token !== "") {
    //         requests.setCookie(constants.ACCESS_TOKEN, access_token, constants.LONG_EXPIRY_DATE);
    //     }
    //
    //     let responseData = await requests.getData(`/api/workflow/order/outbox/${this.search_title}`, constants.ACCESS_TOKEN)
    //     this.setState({
    //         outboxes: responseData.space
    //     })
    // }

    render() {
        return (
            <div className="g-sidenav-show bg-gray-100">
                <Sidebar />
                <div id="content">
                    <Navbar />
                    <div className="container" >
                        <div className="row">
                            <FacebookSDKComponent  />
                            {/*<FacebookLoginComponent appConfig={this.state.appConfig} />*/}
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default FacebookPage;