import requests from "../requests";
import constants from "../constants";


const loadUserData = async () => {
    let responseUser = await requests.getData("/api/user/sso", constants.ACCESS_TOKEN);
    if (responseUser.message === "NOT_FOUND") {
        alert("Không nhận dạng được tài khoản đăng nhập. Vui lòng đăng xuất và đăng nhập lại.")
        return;
    } else {
        return responseUser.data;
    }
}

export default {
    loadUserData : loadUserData
}