import React, {useState, useEffect} from "react";
import LoadingPage from "./LoadingPage";
import Sidebar from "../components/Sidebar";
import Navbar from "../components/Navbar";
import requests from "../requests";
import constants from "../constants";
import userDataService from "../services/userDataService";
import AbstractDataGrid from "../content/datagrid/AbstractDataGrid";
import {Button, Tooltip} from "@mui/material";
import {Form, FormControl, InputGroup } from "react-bootstrap";


const TooltipsFn = [
    {
    columnId : "id",
    type : "button",
    title : "XÁC NHẬN ĐƠN",
    fn : async function (row, callback) {
        let response = (await requests.postData("/api/orderservice/order/crm/change-status", {
            id: row.getValue("id")
        }, constants.ACCESS_TOKEN));
        alert(response.message)
    }},
    {
        columnId : "id",
        type : "button",
        title : "VẬN CHUYỂN ĐƠN HÀNG (KHÔNG GỌI TỚI GHN)",
        fn : async function (row, callback) {
            let response = (await requests.postData("/api/orderservice/order/v1/crm/change-status-shipping", {
                id : row.getValue("id")
            }, constants.ACCESS_TOKEN));
            alert(response.message)
        }
    },
    {
        columnId : "id",
        type : "button",
        title : "VẬN CHUYỂN ĐƠN HÀNG",
        fn : async function (row, callback) {
            let response = (await requests.postData("/api/orderservice/order/v2/crm/change-status-shipping", {
                id : row.getValue("id"),
                storeId : row.getValue("storeId").props.rowInit.storeId
            }, constants.ACCESS_TOKEN));
            alert(response.message)
        }
    },
    {
      columnId : "id",
      type : "button",
      title : "HOÀN TẤT ĐƠN HÀNG",
      fn : async function (row, callback) {
        let response = (await requests.postData("/api/orderservice/order/crm/complete-order", {
          id : row.getValue("id")
        }, constants.ACCESS_TOKEN));
        alert(response.message)
      }
    } ,
    {
      columnId : "id",
      type : "button",
      title : "HỦY ĐƠN",
      fn : async function (row, callback) {
        let response = (await requests.postData("/api/orderservice/order/crm/cancel-order", {
          id : row.getValue("id")
        }, constants.ACCESS_TOKEN));
        alert(response.message)
      }
    }

]

function OrderServicePage(props) {

    const [loaded, setLoaded] = useState(false);
    const [orders, setOrders] = useState([]);
    const [fields, setFields] = useState([]);

    function formHandler(objectSelected) {
        setLoaded(false)
        loadDataGrid(objectSelected).then(result => {
            setLoaded(true)
            setOrders(result.orders);
            setFields(result.fields);
        }).catch();
    }

    useEffect(() => {
        loadDataGrid().then(result => {
            setLoaded(true)
            setOrders(result.orders);
            setFields(result.fields);
        }).catch();
        return () => {
            setOrders([]);
            setFields([]);
            setLoaded(false);
        }
    }, []);


    return (
        <div className="g-sidenav-show bg-gray-100">
            <Sidebar />
            <div id="content">
                <Navbar />
                <div className="container" >
                    <div className="row">
                        <div className="col-12 mb-5">
                            <SimpleFilterProcessDate submitForm={formHandler}/>
                        </div>
                        {
                            loaded ? (
                                <div className="col-12">
                                    {orders.length !== 0
                                        ? (<AbstractDataGrid columns={fields} datasource={orders} tooltips={TooltipsFn} />)
                                        : (<div><p>Hiện tại chưa có đơn hàng</p></div>)
                                    }
                                </div>
                            ) : <LoadingPage />
                        }
                    </div>
                </div>
            </div>
        </div>
    )


}

function SimpleFilterProcessDate({submitForm}) {
    const [inputValue, setInputValue] = useState('');

    function handleInputChange(event) {
        setInputValue(event.target.value);
    }

    function handleSubmit(event) {
        event.preventDefault();
        // Xử lý submit form tại đây
        let objectFiltered = {
            date : inputValue
        }
        submitForm(objectFiltered);
        // console.log('Submit form with value:', inputValue);
    }

    function handleKeyPress(event) {
        if (event.key === 'Enter') {
            handleSubmit(event);
        }
    }

    return (
        <Form>
            <InputGroup>
                <FormControl
                    type="text"
                    value={inputValue}
                    placeholder="Nhập ngày dữ liệu theo format sau DD/MM/YYYY"
                    onChange={handleInputChange}
                    onKeyPress={handleKeyPress}
                />
                <Button color="secondary" onClick={handleSubmit} variant="contained" type="submit">Tìm theo ngày tạo đơn</Button>
            </InputGroup>
        </Form>
    );
}

function parseStringToDate(strDate) {
    if (!strDate) { return null;}
    const [day, month, year] = strDate.split('/');
    return  Date.UTC(+year, +month - 1, +day);
}

async function loadDataGrid(objectFiltered) {
    let access_token = requests.getSessionStorage(constants.ACCESS_TOKEN);
    if (access_token !== "") {
        requests.setCookie(constants.ACCESS_TOKEN, access_token, constants.LONG_EXPIRY_DATE);
    }

    let userData = await userDataService.loadUserData();
    const SPACE_NAME = userData.space;

    let orders = [];

    if (userData) {
        let responseProduct = await requests.postData(`/api/orderservice/order/crm/data-grid`,
            {
                space : SPACE_NAME,
                offset : 0,
                dateSelected: objectFiltered ? objectFiltered.date : "",
                status : "ALL"
            }, constants.ACCESS_TOKEN);
        orders = responseProduct.space;
    }

    // let responseFields = await requests.getData(`/api/space/space-structure/category-master/create-form`, constants.ACCESS_TOKEN);


    let fields = [
        {
            enableColumnFilter : true,
            accessorKey : "id",
            header : "MÃ ĐƠN",
            enableGlobalFilter: false
        },
        {
            enableColumnFilter : true,
            id : "storeId",
            header : "LẤY TỪ SHOP",
            enableGlobalFilter: false,
            accessorFn: ( rowData ) => <SelectStoreId rowInit={rowData} />
        },
        {
            enableColumnFilter : true,
            accessorKey : "customerName",
            header : "TÊN NGƯỜI MUA",
            enableGlobalFilter: false
        }, {
            enableColumnFilter : true,
            accessorKey : "phone",
            header : "SỐ ĐIỆN THOẠI"
        }, {
            enableColumnFilter : true,
            accessorKey : "linkedOrderCode",
            header : "MÃ VẬN ĐƠN"
        }, {
            enableColumnFilter : true,
            accessorKey : "orderMigrationId",
            header : "ĐƠN VỊ VẬN CHUYỂN",
            enableGlobalFilter: false
        },
        {
            enableColumnFilter : true,
            accessorKey : "fullAddress",
            header : "ĐỊA CHỈ GIAO HÀNG"
        }, {
            enableColumnFilter : true,
            accessorKey : "orderLineItems",
            header : "DANH SÁCH MUA",
            enableGlobalFilter: false
        }, {
            enableColumnFilter : true,
            accessorKey : "quantity",
            header : "SÔ LƯỢNG SẢN PHẨM",
            enableGlobalFilter: false
        }, {
            enableColumnFilter : true,
            accessorKey : "totalAmt",
            header : "THÀNH TIỀN",
            enableGlobalFilter: false
        }, {
            enableColumnFilter : true,
            accessorKey : "paymentMethod",
            header : "PHƯƠNG THỨC THANH TOÁN",
            enableGlobalFilter: false
        },
        {
            enableColumnFilter : true,
            accessorKey : "dealer",
            header : "DEALER",
            enableGlobalFilter: false
        },
        {
          enableColumnFilter : true,
          accessorKey : "affiliateCode",
          header : "AFFILIATE CODE",
          enableGlobalFilter: false
        },
        {
          enableColumnFilter : true,
          accessorKey : "noted",
          header : "GHI CHÚ",
          enableGlobalFilter: false
        },
        {
            enableColumnFilter : true,
            accessorKey : "createdDate",
            header : "NGÀY ĐẶT HÀNG",
            enableGlobalFilter: false
        }, {
            enableColumnFilter : true,
            accessorKey : "status",
            header : "TRẠNG THÁI ĐƠN HÀNG"
        }
    ];

    return {
        fields: fields,
        orders: orders
    }
}

const SelectStoreId = ({ rowInit }) => {
  const [value, setValue] = useState(() => {
    if (rowInit.storeId) {

    } else {
      rowInit.storeId = "1270418";
    }
    return rowInit.storeId;
  });

  const handleChange = (event) => {
    const newValue = event.target.value
    setValue(newValue)
    rowInit.storeId = newValue
    // Perform any necessary logic or API calls here
  };

  if (rowInit.status !== undefined && (rowInit.status !== "SHIPPING" || rowInit.status !== "COMPLETED" || rowInit.status !== "CANCELLED")) {
    return (
        <select value={value} onChange={handleChange}>
          <option value="1270418">MeyMall SG</option>
          <option value="3721238">MeyMall HN</option>
          <option value="4085492">Yoda Store</option>
        </select>
    );
  } else {
    return <input disabled={true} value={value} />
  }
};


export default OrderServicePage;