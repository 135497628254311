import React, {useState, useEffect} from 'react';
import NewsPageContent from "../../content/news-page/NewsPageContent"
import accessTokenService from "../../services/accessTokenService";
import requests from "../../requests";
import constants from "../../constants";
import Sidebar from "../../components/Sidebar";
import Navbar from "../../components/Navbar";
import LoadingPage from "../LoadingPage";
import userDataService from "../../services/userDataService";
import {Button, Form, FormControl, InputGroup} from "react-bootstrap";


const NewsPage = () => {

    const [news, setNews] = useState([]);
    const [loaded, setLoaded] = useState(false);
    const [searchQuery, setSearchQuery] = useState('');

    // Hàm xử lý tìm kiếm
    const handleSearchQuery = (e) => {
        e.preventDefault();
        // Thêm logic tìm kiếm tại đây
        setSearchQuery(e.target.value);
    };

    const handleSearchSubmit = (e) => {
        e.preventDefault();

        const queryCondition = {
            product_code : searchQuery
        }

        // Thêm logic tìm kiếm tại đây
        initialNewsPage(queryCondition).then(result => {
            setNews(result);
        });
    };


    useEffect(() => {
        setLoaded(false);
        initialNewsPage(null).then(result => {
            setNews(result);
            setLoaded(true);
            setSearchQuery("");
        });
    }, []);

    if(loaded) {
        return (
            <div className="g-sidenav-show  bg-gray-100">
                <Sidebar />
                <div id="content">
                    <Navbar />
                    <div className="container" >
                        <div className="row">
                            <div className="col-12 mb-3">
                                <a className="btn btn-primary" href="/news/create-form">Thêm tin tức</a>
                                {/* Form tìm kiếm luôn hiển thị */}
                                <Form className="d-flex flex-grow-1 ms-3" onSubmit={handleSearchSubmit}>
                                    <InputGroup>
                                        {/* Ô tìm kiếm */}
                                        <FormControl
                                            type="search"
                                            placeholder="Nhập mã bài viết..."
                                            aria-label="Nhập nhập mã bài viết..."
                                            value={searchQuery}
                                            onChange={handleSearchQuery}
                                        />
                                        {/* Nút tìm kiếm màu xám nhạt */}
                                        <Button variant="secondary" type="submit">Tìm</Button>
                                    </InputGroup>
                                </Form>
                            </div>
                            {
                                Array.isArray(news) && news.length !== 0 ? (
                                        <NewsPageContent news={news} />
                                    ) :
                                    (<div>
                                        <p>Không có bài viết</p>
                                    </div>)
                            }
                        </div>
                    </div>
                </div>
            </div>
        )
    } else {
        return <LoadingPage />
    }
}

async function initialNewsPage(queryCondition) {
    accessTokenService.initialAccessToken();
    const userData = await userDataService.loadUserData();
    let responseData = await requests.getData(buildQuery(userData, queryCondition), constants.ACCESS_TOKEN);
    if (responseData.space) {
        return responseData.space;
    } else {
        return [];
    }
}

function buildQuery(userData, queryCondition) {
    if (queryCondition === null) {
        return `/api/v1/news/manage-pageable-news?space=${userData.space}&pageable=publication_date,-1,1,100`;
    }
    return `/api/v1/news/manage-pageable-news?space=${userData.space}&pageable=publication_date,-1,1,100&product_code=${queryCondition.product_code}`;
}

export default NewsPage;