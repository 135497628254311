import React, { useState, useEffect } from "react";
import AbstractDataGrid from "../content/datagrid/AbstractDataGrid";
import requests from "../requests";
import constants from "../constants";
import userDataService from "../services/userDataService";
import LoadingPage from "./LoadingPage";
import Sidebar from "../components/Sidebar";
import Navbar from "../components/Navbar";
import {Link} from 'react-router-dom';
import { Form, Button } from 'react-bootstrap';

export default (props) => {

    const [loaded, setLoaded] = useState(false);
    const [variantList, setVariantList] = useState([]);
    const [fields, setFields] = useState([]);
    const [title, setTitle] = useState('');
    const [content, setContent] = useState('');
    const [voice, setVoice] = useState('leminh');

    const handleSubmit = (e) => {
        e.preventDefault()

        // Logic xử lý khi submit form
        requests.postData(`/api/v1/metaverse/tts/generate`,
             { title: title, content : content, voice : voice },
             constants.ACCESS_TOKEN)
            .then(response => response.space)
            .then(data => {
                alert('Success: ' +  data);
                // Sau khi xử lý xong, reload lại trang
                window.location.reload();
            })
            .catch((error) => {
                alert('Error: ' + error);
            });
    };

    useEffect(() => {
        initialDatagrid().then(result => {
            setLoaded(true)
            setVariantList(result.variantList);
            setFields(result.fields);
        }).catch();
        return () => {
            setVariantList([]);
            setFields([]);
            setLoaded(false);
        }
    }, []);

    if (loaded) {
        return (
            <div className="g-sidenav-show  bg-gray-100">
                <Sidebar />
                <div id="content">
                    <Navbar navbarItems={constants.NAVBAR_PRODUCT_ITEMS}/>
                    <div className="container">
                        <Form onSubmit={handleSubmit}>
                            {/* Tiêu đề bài viết */}
                            <Form.Group controlId="formTitle">
                                <Form.Label>Tiêu đề bài viết</Form.Label>
                                <Form.Control
                                    type="text"
                                    placeholder="Nhập tiêu đề bài viết"
                                    value={title}
                                    onChange={(e) => setTitle(e.target.value)}
                                />
                            </Form.Group>

                            <Form.Group controlId="formTitle">
                                <Form.Label>Chọn giọng đọc - FTP</Form.Label>
                                <Form.Control as="select" value={voice} onChange={(e) => {
                                    e.preventDefault();
                                    setVoice(e.target.value)
                                }}>
                                    <option value="leminh">-- Chọn (Mặc định Lê Minh - Nam miền Bắc --</option>
                                    {/* Placeholder option */}
                                    <option value="leminh">Lê Minh - Nam miền Bắc</option>
                                    <option value="banmai">Ban Mai - Nữ miền Bắc</option>
                                    <option value="thuminh">Thu Minh - Nữ miền Bắc</option>
                                    <option value="myan">Mỹ An - Nữ miền Trung</option>
                                    <option value="giahuy">Gia Huy - Nam miền Trung</option>
                                    <option value="ngoclam">Ngọc Lam - Nữ miền Trung</option>
                                    <option value="minhquang">Minh Quang - Nam miền Nam</option>
                                    <option value="linhsan">Linh San - Nữ miền Nam</option>
                                    <option value="lannhi">Lan Nhi - Nữ miền Nam</option>
                                </Form.Control>
                            </Form.Group>

                            {/* Hộp văn bản rất rộng */}
                            <Form.Group controlId="formContent" className="mt-3">
                                <Form.Label>Nội dung bài viết</Form.Label>
                                <Form.Control
                                    as="textarea"
                                    placeholder="Nhập nội dung bài viết"
                                    rows={20}  // Số hàng của textarea, làm nó cao hơn
                                    value={content}
                                    onChange={(e) => setContent(e.target.value)}
                                    style={{width: '100%', height: '400px'}}  // Làm cho nó rất rộng và cao
                                />
                            </Form.Group>

                            {/* Nút submit */}
                            <Button variant="primary" type="submit" className="mt-3">
                                Tạo Media đọc văn bản
                            </Button>
                        </Form>
                    </div>
                    <div className={"line"}/>
                    <div className="container" >
                        <AbstractDataGrid columns={fields} datasource={variantList} />
                    </div>
                </div>
            </div>
        )
    } else {
        return <LoadingPage />
    }

}

function buildTemplateMetaverseDatagrid(listName) {
    return listName
        .map(name => {
        if (name === "link") {
            return {
                enableColumnFilter : true,
                accessorKey : name,
                id : name,
                header : name,
                Cell: ({ cell }) => (  // Sử dụng Cell để render HTML
                    <a className="text-primary" href={cell.getValue()} target="_blank" rel="noopener noreferrer">
                        {cell.getValue()}
                    </a>
                )
            }
        }
        return {
            enableColumnFilter : true,
            accessorKey : name,
            id : name,
            header : name
        }
    });
}

async function initialDatagrid() {
    let access_token = requests.getSessionStorage(constants.ACCESS_TOKEN);
    if ( access_token !== "") {
        requests.setCookie(constants.ACCESS_TOKEN, access_token, constants.LONG_EXPIRY_DATE);
    }

    let userData = await userDataService.loadUserData();
    const SPACE_NAME = userData.space;

    let variantList = [];

    if (userData) {
        let responseProduct = await requests.getData(`/api/v1/metaverse/tts/checklist`, constants.ACCESS_TOKEN);
        variantList = await responseProduct.space;
    }

    return {
        fields: buildTemplateMetaverseDatagrid(Object.keys(variantList.length !== 0 ? variantList[0] : {}), "/metaverse"),
        variantList: variantList
    }
}
