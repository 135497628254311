import React from 'react';
import FigureComponent from './FigureComponent';
import constants from "../constants"
import requests from "../requests";

const navbarData = [
    {
        _id : "1",
        href: "/products",
        src: "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcT7s05REmBUWcbI71JPF7bGAwzTrDGyFgAnjA&usqp=CAU",
        text: "Sản phẩm"
    },
    {
        _id : "2",
        href: "/outboxes",
        src: "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTF9LdKikLXJ-ihHzICuvot44oHXkr76gINWQ&usqp=CAU",
        text: "Đơn hàng"
    }
]

// const midNavbar = [
//     {
//         category : "/products/"
//     }
// ]

class Navbar extends React.Component {
    constructor(props) {
        super(props);
        this.navbarData = props.navbarItems ? props.navbarItems : [];
        this.state = {
            sidebarActive: false
        };
        this.currentPath = window.location.pathname;
        this.handleOnclickCloseSidebarCollapse = this.handleOnclickCloseSidebarCollapse.bind(this);
        this.handleOnclickOpenSidebarCollapse = this.handleOnclickOpenSidebarCollapse.bind(this);
        this.handleLogoutAccount = this.handleLogoutAccount.bind(this);

    }

    // async componentWillMount() {
    //     let response = await requests.getData("/api/product-statistic/list-item?category=PRODUCT_CATEGORY&space=mouseindahouse_duong", constants.ACCESS_TOKEN);
    //     if (response.space) {
    //         this.setState({
    //             navbarData : response.space
    //         });
    //     }
    // }

    handleOnclickCloseSidebarCollapse() {
        this.setState({
            sidebarActive: true
        })
        let sidebarElement = document.getElementById("sidebar");
        sidebarElement.classList.add("active");
        let contentElement = document.getElementById("content");
        contentElement.classList.add("active");
    }

    handleOnclickOpenSidebarCollapse() {
        this.setState({
            sidebarActive: false
        })
        let sidebarElement = document.getElementById("sidebar");
        sidebarElement.classList.remove("active");
        let contentElement = document.getElementById("content");
        contentElement.classList.remove("active");
    }

    handleLogoutAccount() {
        requests.deleteSessionStorage(constants.ACCESS_TOKEN);
        requests.eraseCookie(constants.ACCESS_TOKEN);
        requests.deleteSessionStorage(constants.USER_SSO);
        window.location.href = "/";
    }

    render() {
        return (
            <nav class="navbar navbar-expand navbar-light d-flex justify-content-between">
                <button type="button" id="sidebarCollapse" onClick={this.state.sidebarActive
                    ? this.handleOnclickOpenSidebarCollapse
                    : this.handleOnclickCloseSidebarCollapse}
                    aria-expanded="false" aria-label="Toggle navigation"
                    className="btn btn-info navbar-btn">
                    <i class="bi bi-list"></i>
                    <span>Menu</span>
                </button>
                <button className="navbar-toggler" type="button" data-toggle="collapse"
                        data-target="#navbarMenuData" aria-controls="navbarSupportedContent"
                        aria-expanded="false" aria-label="Toggle navigation">
                    <span className="navbar-toggler-icon"></span>
                </button>
                <div className="collapse navbar-collapse" id="navbarMenuData">
                <ul class="navbar-nav mr-auto" style={{ right: 0 }} >
                    {
                        this.navbarData.map(data => {
                            return (
                                <li className="nav-item float-left" key={data.key}>
                                    <a className="nav-link ml-1" href={data.href}>
                                        <small>
                                            {data.value}
                                        </small>
                                        {/*<FigureComponent*/}
                                        {/*    style={{ width: "2.5rem", height: "1rem" }}*/}
                                        {/*    src={data.src}*/}
                                        {/*    text={data.text}*/}
                                        {/*    textColor={this.currentPath === data.href ? "text-primary" : "text-dark"}*/}
                                        {/*/>*/}
                                    </a>
                                </li>
                            )
                        })
                    }
                </ul>
                </div>
                <div>
                    <button className="btn btn-secondary" onClick={this.handleLogoutAccount}>
                        Đăng xuất
                    </button>
                </div>
            </nav>
        )
    }
}

export default Navbar;