import React from 'react';

import { Card, Row } from 'react-bootstrap';
import ReactMarkdown from 'react-markdown';

import util from "../util/util";

let borderRadius = "0.3rem"
let cardSubStyleSheet = {
    hover: "",
    background: "white",
    borderRadius: "2"
}

let cardImageSubStyleSheet = {
    width: "100%",
    borderRadius: borderRadius
}

// function unescapeHTML(html) {
//     var escapeEl = document.createElement('div');
//     escapeEl.innerHTML = html;
//     return escapeEl;
// }

function VideoComponentFrame({ children }) {
    return (
        <iframe width="560" height="315" src={children} title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
    )
}

function VideoComponent({ children }) {
    return <div dangerouslySetInnerHTML={{ __html: children }}></div>;
}

export default class ProductView extends React.Component {
    constructor(props) {
        super(props);
        this.product = props.product;
    }

    render() {
        const product = this.product;

        return (
            <div className="col-xs-12 col-12 col-md-9 col-lg-9">
                <Card className="box shadow w-100 p-3 container-fluid" style={cardSubStyleSheet}>
                    <Row>
                        <div className="col-xs-12 col-12 col-lg-6">
                            <Card.Img variant="top" style={cardImageSubStyleSheet}
                                src={product.background ? `/blob/${product.background}` :
                                    "https://blog.sqlauthority.com/wp-content/uploads/2007/06/null-500x259.png"} />

                        </div>
                        <div className="col-xs-12 col-12 col-lg-6">
                            <h4>{product.label}</h4>
                            <div className="line"></div>
                            <div className="">
                                <span className="">Giá: <h4>{util.beautyNumber(product.promotion)} đ <small><del>{util.beautyNumber(product.price)} đ</del></small></h4></span>
                            </div>
                            <div className="line"></div>
                            <label>Mô tả sản phẩm: </label>
                            <div className="">
                                {product.description}
                            </div>
                            <div className="line"></div>
                            <label>Số lượng</label>
                            <div class="input-group mb-3">
                                <input className="form-control" type="number" />
                                <div class="input-group-append">
                                    <span class="input-group-text">/ 1 {product.unit}</span>
                                </div>
                            </div>
                            <button className="btn btn-primary">Thêm vào giỏ hàng</button>

                        </div>
                        <div className="col-xs-12 col-12 col-md-12 mt-5">
                            <Card.Body className="justify-content-center">
                                <Card.Title align={"center"}>Thông Tin Chi Tiết</Card.Title>
                                <Card.Text>
                                    {product.specifications}
                                </Card.Text>
                                <div className="line"></div>
                                <Card.Title align={"center"}>Mô Tả Sản Phẩm</Card.Title>
                                <Card.Text className="">
                                    {product.description}
                                </Card.Text>
                                <div className="line"></div>
                                <Card.Title align={"center"}>Nội dung</Card.Title>
                                <Card.Text className="">
                                    {
                                        product.content !== undefined ?
                                            <Features features={product.content} />
                                            :
                                            (
                                                <span>Không có nội dung</span>
                                            )
                                    }
                                </Card.Text>
                                <div className="line"></div>
                            </Card.Body>
                        </div>
                    </Row>
                </Card>
            </div>
        )
    }
}

const Features = (props) => {
    const { features } = props;
    const content = (features ? features : "").split("\n");
    if (content.length === 0) {
        return (
            <div>Không có content</div>
        )
    }
    else {
        return (
            content.map(subContent => {
                if (subContent.includes("#productvideo")) {
                    return (
                        <VideoComponentFrame>{subContent}</VideoComponentFrame>
                    )
                }
                if (subContent.includes("https://www.youtube.com/embed/") && subContent.includes("<iframe")) {
                    return (
                        <VideoComponent>{subContent}</VideoComponent>
                    )
                }
                return (
                    <ReactMarkdown>{subContent}</ReactMarkdown>
                );
            })
        )
    }


} 