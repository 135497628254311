import React from 'react';
import axios from 'axios';

import util from "../util/util";
import constants from "../constants";
import cartRequest from "../requests/cartRequests";

const { ACCTION_ADD_TO_CART, ACTION_REMOVE_FROM_CART, ACTION_CLEAR_CART, ACTION_GET_CART, ACTION_UPDATE_ITEM_CART } = constants;

class ProductContent extends React.Component {
    constructor(props) {
        super(props);
        // this.event = props.event;
        this.state = {
            products: props.products,
            scrolling: false,
            productsCart: [],
            observer_space: []
        }
        this.handleScrollLoadSpace = this.handleScrollLoadSpace.bind(this);
        this.handleAddProductToCart = this.handleAddProductToCart.bind(this);
    }

    handleAddProductToCart(product) {
        let payload = {
            data: product,
            action: ACCTION_ADD_TO_CART
        }
        let cart = cartRequest(payload);
        this.setState({
            productsCart: cart
        });
        return cart;
    }

    async onInfiniteScroll() {
        window.addEventListener('scroll', async () => {
            const { scrollTop, scrollHeight, clientHeight } = document.documentElement;
            if (clientHeight + scrollTop >= scrollHeight - 2) {

                if (this.state.scrolling) {
                    return;
                }
                this.setState({ scrolling: true });
                setTimeout(() => {
                    this.handleScrollLoadSpace();
                }, 300);

            }
        });
    }

    async handleScrollLoadSpace() {
        if (this.state.next !== undefined) {
            let url = `/api/productservice/product/loadspace/${this.state.next}`
            let response = await axios.get(url);
            await this.setState({
                observer_space: [...this.state.observer_space, ...await response.data.space],
                next: await response.data.next,
                scrolling: false
            });
        }
    }

    async componentDidMount() {
        await this.onInfiniteScroll();
    }

    render() {
        return (
            <div className="row">
                {this.state.products.map(product => {
                    return (
                        <ProductCard cartAction={this.handleAddProductToCart} key={product._id} product={product}></ProductCard>
                    )
                })}
            </div>
        )
    }
}

function ProductCard(props) {
    const { cart, cartAction } = props;
    let product = props.product;

    function addProductToCard() {
        product.purchase = 1;
        if (cartAction(product).length !== 0) {
            alert("Thêm sản phẩm vô giỏ hàng thành công");
        };
    }
    let borderRadius = "0.2rem"
    let cardSubStyleSheet = {
        borderRadius: borderRadius
    }

    let cardImageSubStyleSheet = {
        borderTopLeftRadius: borderRadius,
        borderTopRightRadius: borderRadius
    }

    return (
        <div className="col-xs-12 col-6 col-md-4 col-lg-3 m-1 p-2 float-left d-flex bg-light justify-content-between card shadow"
            style={{ width: "200px"}}>
            <a href={"/product/" + product.id} className="text-blue">
                <img className="card-img-top"
                    style={{ width: "100%", height: "15rem", objectFit: "cover" }}
                    src={
                        product.background === ""
                            ? "https://blog.sqlauthority.com/wp-content/uploads/2007/06/null-500x259.png"
                            : `/blob/${product.background}`} alt={product.description} />
                <h6 className="mt-3">{product.name}</h6>
            </a>
            <div></div>
            <div className="card-footer bg-white">
                <p className="card-text">{product.description}</p>
                <button className="btn btn-secondary w-100"> <a href={"/create-form?product_code=" + product.id}>Chỉnh sửa</a></button>
                <div class="align-self-end input-group mb-3">
                    {/*<div class="input-group-prepend">*/}
                    {/*    <span class="input-group-text">Giá: {util.beautyNumber(product.promotion)} đ</span>*/}
                    {/*</div>*/}
                    {/*<button className="form-control btn btn-success" onClick={addProductToCard}>Mua</button>*/}
                </div>
            </div>
        </div>
    )
}

export default ProductContent;