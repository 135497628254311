import React, { useCallback, useMemo, useState } from 'react';
import MaterialReactTable from 'material-react-table';
import {
    Box,
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    IconButton,
    MenuItem,
    Stack,
    TextField,
    Tooltip
} from '@mui/material';
import {Link} from 'react-router-dom';
import {Approval, Delete, Edit, Apps} from '@mui/icons-material';
// import { data, states } from './makeData';

const AbstractDataGrid = ({columns, datasource, createFn, updateFn, deleteFn, tooltips}) => {
    tooltips = tooltips ? tooltips : []
    const [createModalOpen, setCreateModalOpen] = useState(false);
    const [tableData, setTableData] = useState(datasource);
    const [validationErrors, setValidationErrors] = useState({});

    // const handleCancelRowEdits = () => {
    //     setValidationErrors({});
    // };

    // const getCommonEditTextFieldProps = useCallback(
    //     (cell) => {
    //         return {
    //             error: !!validationErrors[cell.id],
    //             helperText: validationErrors[cell.id],
    //             onBlur: (event) => {
    //                 const isValid =
    //                     cell.column.id === 'email'
    //                         ? validateEmail(event.target.value)
    //                         : cell.column.id === 'age'
    //                             ? validateAge(+event.target.value)
    //                             : validateRequired(event.target.value);
    //                 if (!isValid) {
    //                     //set validation error for cell if invalid
    //                     setValidationErrors({
    //                         ...validationErrors,
    //                         [cell.id]: `${cell.column.columnDef.header} is required`,
    //                     });
    //                 } else {
    //                     //remove validation error for cell if valid
    //                     delete validationErrors[cell.id];
    //                     setValidationErrors({
    //                         ...validationErrors,
    //                     });
    //                 }
    //             },
    //         };
    //     },
    //     [validationErrors],
    // );

    return (
        <>
            <MaterialReactTable
                displayColumnDefOptions={{
                    'mrt-row-actions': {
                        muiTableHeadCellProps: {
                            align: 'center',
                        },
                        size: 120,
                    },
                }}
                columns={columns}
                data={datasource}
                enableGlobalFilter
                editingMode="modal" //default
                enableColumnOrdering
                enableEditing
                rowsPerPage="1000"
                onEditingRowSave={updateFn ? updateFn : false}
                // onEditingRowCancel={handleCancelRowEdits}
                renderRowActions={({ row, table }) => (
                    <Box sx={{ display: 'flex', gap: '1rem' }}>
                        {updateFn ? (
                            <Tooltip arrow placement="left" title="chỉnh sửa">
                            {
                                updateFn.type === "link" ? (
                                    <Link to={updateFn.href + row.getValue(updateFn["columnId"])}>
                                        <Edit />
                                    </Link>
                                ) : (
                                    <IconButton onClick={() => updateFn.fn(row.getValue(updateFn["columnId"]))}>
                                        <Edit />
                                    </IconButton>
                                )
                            }
                        </Tooltip>) : <></>}
                        {deleteFn ? (
                            <Tooltip arrow placement="right" title="xóa">
                                <IconButton color="error" onClick={() => deleteFn(row)}>
                                    <Delete />
                                </IconButton>
                            </Tooltip>
                        ) : <></>}
                        {
                            tooltips.map(tooltip =>
                                tooltip ? (<Tooltip  arrow placement="right" title={tooltip.title}>
                                    {
                                        tooltip.type === "link" ? (
                                            <Link to={tooltip.href + row.getValue(tooltip["columnId"])}>
                                                {tooltip.title}
                                            </Link>
                                        ) : (
                                            <IconButton onClick={() =>  tooltip.fn(row)}>
                                                <Apps />
                                            </IconButton>
                                        )
                                    }
                                </Tooltip>) : (<div></div>)
                            )
                        }
                    </Box>
                )}
                renderTopToolbarCustomActions={() => (
                    <Button
                        color="secondary"
                        onClick={() => setCreateModalOpen(true)}
                        variant="contained"
                    >
                        Thêm Danh sách
                    </Button>
                )}
            />
            {
                createFn ? (createFn.type === "link" ? (
                    <Tooltip arrow placement="left" title={createFn.title}>
                        <Link to={createFn.href}>
                            <Edit />
                        </Link>
                    </Tooltip>
                ) : (
                    <CreateNewAccountModal
                        columns={columns}
                        open={createModalOpen}
                        onClose={() => setCreateModalOpen(false)}
                        onSubmit={createFn.fn}
                    />
                )) : <></>

            }
        </>
    );
};

//AbstractDataGrid of creating a mui dialog modal for creating new rows
export const CreateNewAccountModal = ({ open, columns, onClose, onSubmit }) => {
    const [values, setValues] = useState(() =>
        columns.reduce((acc, column) => {
            acc[column.accessorKey ?? ''] = '';
            return acc;
        }, {}),
    );

    const handleSubmit = () => {
        //put your validation logic here
        onSubmit(values);
        onClose();
    };

    return (
        <Dialog open={open}>
            <DialogTitle textAlign="center">Tạo dòng dữ liệu</DialogTitle>
            <DialogContent>
                <form onSubmit={(e) => e.preventDefault()}>
                    <Stack
                        sx={{
                            width: '100%',
                            minWidth: { xs: '300px', sm: '360px', md: '400px' },
                            gap: '1.5rem',
                        }}
                    >
                        {columns.map((column) => (
                            <TextField
                                key={column.accessorKey}
                                label={column.header}
                                name={column.accessorKey}
                                onChange={(e) =>
                                    setValues({ ...values, [e.target.name]: e.target.value })
                                }
                            />
                        ))}
                    </Stack>
                </form>
            </DialogContent>
            <DialogActions sx={{ p: '1.25rem' }}>
                <Button onClick={onClose}>Cancel</Button>
                <Button color="secondary" onClick={handleSubmit} variant="contained">
                    THÊM DANH SÁCH
                </Button>
            </DialogActions>
        </Dialog>
    );
};

const validateRequired = (value) => !!value.length;
const validateEmail = (email) =>
    !!email.length &&
    email
        .toLowerCase()
        .match(
            /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
        );
const validateAge = (age) => age >= 18 && age <= 50;

export default AbstractDataGrid;
