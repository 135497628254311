import React from "react";

import requests from "../../requests";
import constants from "../../constants";
import Statistic from "../../components/createform/Statistic";
import Selected from "../../components/populate/Selected";
import MultiSelected from "../../components/populate/MultiSelected";
import DetectedInput from "../../components/createform/DetectedInput";
import util from "../../util/util";

const DOMAIN = window.location.hostname;

export default class AbstractUpdateForm extends React.Component {
    constructor(props) {
        super(props);
        this.product = props.product;
        this.createFields = util.notNull(this.product) ? this.product : {};
        this.fields = props.fields;
        this.updateApi = props.updateApi;
        this.deleteApi = props.deleteApi;
        this.redirectLocation = props.redirectLocation;
        this.state = {
            changed : {}
        }

        this.handleUpdateRequest = this.handleUpdateRequest.bind(this);
        this.handleDeleteRequest = this.handleDeleteRequest.bind(this);
        this.handleOnChangeRequest = this.handleOnChangeRequest.bind(this);
        this.handleOnChangeRequestBlobStore = this.handleOnChangeRequestBlobStore.bind(this);
    }

    handleUpdateRequest() {
        requests.postData(`${this.updateApi}`, this.createFields, constants.ACCESS_TOKEN).then(response => {
            if (response.message === "SUCCESS") {
                alert("Cập nhật thành công");
                if (this.redirectLocation) {
                    window.location.href = this.redirectLocation;
                }
            } else {
                let message = response.message;
                alert("Cập nhật thất bại: " + message);
            }
        });
    }

    handleOnChangeRequest(event) {
        event.preventDefault();
        const id = event.target.id;
        const val = event.target.value;
        console.log(id);
        console.log(event.target.value)
        this.createFields[id] = val;
        let objectChangeData = {};
        objectChangeData[id] = val;
        console.log(objectChangeData);
        this.setState({
            changed: objectChangeData
        });
    }

    handleOnChangeRequestBlobStore(event) {
        event.preventDefault();
        const id = event.target.id;
        const val = event.target.value.split(",");
        this.createFields[id] = val;

        let objectChangeData = {};
        objectChangeData[id] = val;
        this.setState({
            changed: objectChangeData
        });
    }

    handleDeleteRequest() {
        let deleteBody = {
            product_code: this.createFields["product_code"]
        }
        requests.postData(this.deleteApi, deleteBody, constants.ACCESS_TOKEN).then(response => {
            if (response.message === "SUCCESS") {
                alert("Xóa thành thành công");
                if (this.redirectLocation) {
                    window.location.href = this.redirectLocation;
                }
            } else {
                let message = response.message;
                alert("Xóa thất bại: " + message);
            }
        });
    }

    render() {
        let renderFields = this.fields.filter(field => !field.hidden);
        let filterField = renderFields.filter(field => field.type === "selected");
        let selectedField = Array.isArray(filterField) && filterField.length ? filterField[0] : null;

        return (
            <div className="container-fluid col-12 col-md-9 card shadow mt-3">
                <div className="card-body row">

                    {
                        selectedField ? (
                            <Selected field={selectedField} value={this.product[selectedField.key]}
                                      domain={DOMAIN}
                                      onChange={this.handleOnChangeRequestBlobStore}/>) : <></>
                    }

                    <div className="form-row m-1">
                        {
                            renderFields.map(field => {
                                if (field.section === 'a') {
                                    if (field.type === "statistic") {
                                        if (field.auto_populate && field.auto_populate.require_key !== undefined) {
                                            return <Statistic key={field.key} field={field}
                                                              createFields={this.createFields} value={this.product[field.key]}
                                                              changeData={this.state.changed} onChange={this.handleOnChangeRequest}/>
                                        } else {
                                            return <Statistic key={field.key} field={field}
                                                              createFields={this.createFields} value={this.product[field.key]}
                                                              onChange={this.handleOnChangeRequest}/>
                                        }
                                    } else if (field.type === "multiSelected") {
                                        return <MultiSelected
                                            field={field} createFields={this.createFields}
                                            domain={DOMAIN}
                                            value={this.product[field.key]}
                                            hiddenUuid={false}
                                            onChange={this.handleOnChangeRequestBlobStore}
                                        />
                                    } else {
                                        return <DetectedInput key={field.key} createFields={this.createFields}
                                                              field={field} value={this.product[field.key]}
                                                              onChange={this.handleOnChangeRequest}/>
                                    }
                                }
                            })
                        }
                        <div className="d-flex justify-content-between w-100 p-5">
                            <button type="button" className="m-3 btn btn-primary"
                                    onClick={this.handleUpdateRequest}><i className="bi bi-cloud-arrow-up"/> Lưu chỉnh sửa
                            </button>
                            <button type="button" className="btn btn-danger float-right m-3"
                                    data-toggle="modal" data-target="#deleteProductModel">
                                <i className="bi bi-cloud-arrow-up"/> Xóa sản phẩm
                            </button>

                            <div className="modal fade" id="deleteProductModel" tabIndex="-1" role="dialog"
                                 aria-hidden="true">
                                <div className="modal-dialog" role="document">
                                    <div className="modal-content">
                                        <div className="modal-header">
                                            <h5 className="modal-title">Bạn có chắc muốn xóa sản phẩm ?</h5>
                                            <button type="button" className="close" data-dismiss="modal"
                                                    aria-label="Close">
                                                <span aria-hidden="true">&times;</span>
                                            </button>
                                        </div>
                                        <div className="modal-body">
                                            <button type="button" className="btn btn-danger"
                                                    onClick={this.handleDeleteRequest} data-dismiss="modal">Xóa
                                            </button>
                                        </div>
                                        <div className="modal-footer">
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <button className="m-3 btn btn-secondary float-right"><a
                                href={this.redirectLocation ? this.redirectLocation : "/"}> Hủy </a></button>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}
