const ACCESS_TOKEN = "access_token";
const LONG_EXPIRY_DATE = 60;
const USER_SSO = "user_sso";
const VALID_ACCESS_TOKEN_RESPONSE = "VALID";
const EXPIRED_ACCESS_TOKEN_RESPONSE = "EXPIRED";
const WAITING = "WAITING";
const DELIVERING = "DELIVERING";
const APPROVED = "APPROVED";
const CANCELLED = "CANCELLED";
const COMPLETED = "COMPLETED";
/* CART ACTION */
const ACTION_ADD_TO_CART = "ACCTION_ADD_TO_CART";
const ACTION_GET_CART = "ACTION_GET_CART";
const ACTION_UPDATE_ITEM_CART = "ACTION_UPDATE_ITEM_CART";
const ACTION_REMOVE_FROM_CART = "ACTION_REMOVE_FROM_CART";
const ACTION_CLEAR_CART = "ACTION_CLEAR_CART";
/* USER ACTION */
const ACTION_GET_USER_INFORMATION = "ACTION_GET_USER_INFORMATION";
const ACTION_SET_USER_INFORMATION = "ACTION_SET_USER_INFORMATION";
const ACTION_UPDATE_USER_INFORMATION = "ACTION_UPDATE_USER_INFORMATION";
const ACTION_DELETE_USER_INFORMATION = "ACTION_DELETE_USER_INFORMATION";

const NAVBAR_PRODUCT_ITEMS = [
    {
        _id : "1",
        href: "/category/create-form",
        src: "",
        value: "Thêm danh mục con"
    },
    {
        _id : "2",
        href: "/category-master/create-form",
        src: "",
        value: "Thêm danh mục chính"
    },
    {
        _id : "3",
        href: "/variant/create-form",
        src: "",
        value: "Thêm mẫu hàng"
    },
    {
        _id : "4",
        href: "/variantprice/create-form",
        src: "",
        value: "Thêm bảng giá"
    },

];

export default {
    ACCESS_TOKEN: ACCESS_TOKEN,
    LONG_EXPIRY_DATE: LONG_EXPIRY_DATE,
    USER_SSO: USER_SSO,
    VALID_ACCESS_TOKEN_RESPONSE: VALID_ACCESS_TOKEN_RESPONSE,
    EXPIRED_ACCESS_TOKEN_RESPONSE: EXPIRED_ACCESS_TOKEN_RESPONSE,
    WAITING: WAITING,
    DELIVERING: DELIVERING,
    APPROVED: APPROVED,
    CANCELLED: CANCELLED,
    COMPLETED: COMPLETED,
    ACTION_ADD_TO_CART: ACTION_ADD_TO_CART,
    ACTION_GET_CART: ACTION_GET_CART,
    ACTION_UPDATE_ITEM_CART: ACTION_UPDATE_ITEM_CART,
    ACTION_REMOVE_FROM_CART: ACTION_REMOVE_FROM_CART,
    ACTION_CLEAR_CART: ACTION_CLEAR_CART,

    ACTION_GET_USER_INFORMATION: ACTION_GET_USER_INFORMATION,
    ACTION_SET_USER_INFORMATION: ACTION_SET_USER_INFORMATION,
    ACTION_UPDATE_USER_INFORMATION: ACTION_UPDATE_USER_INFORMATION,
    ACTION_DELETE_USER_INFORMATION: ACTION_DELETE_USER_INFORMATION,

    ACTION_ADD_SEEN_NOTIFICATIONS: "ACTION_ADD_SEEN_NOTIFICATIONS",
    ACTION_GET_SEEN_NOTIFICATIONS: "ACTION_GET_SEEN_NOTIFICATIONS",

    NAVBAR_PRODUCT_ITEMS : NAVBAR_PRODUCT_ITEMS
};

