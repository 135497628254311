import {Form} from "react-bootstrap";
import React from "react";

export default function TextBox(props) {

    const { field, onChange, value } = props;

    return (
        <Form.Group className="col-xs-12 col-12 col-md-12 p-1" key={field.key}>
            <Form.Label>{field.label}</Form.Label>
            <Form.Control className="border border-radius"
                          id={field.key} as="textarea" rows={5}
                          value={value}
                          onChange={onChange}
                          placeholder={field.label.toLowerCase()}
            />
        </Form.Group>
    )
}