import React, { useState, useEffect } from 'react';
import emptyImage from '../../add-image-empty.png';

import util from "../../util/util";
import requests from '../../requests';
import constants from '../../constants';

class MultiSelected extends React.Component {
    constructor(props) {
        super(props);
        let value = util.notNull(props.value) ? props.value : [];
        this.DOMAIN = props.domain;
        //this.hiddenUuid = !props.hiddenUuid ? props.hiddenUuid : true;
        this.hiddenUuid = false;
        this.state = {
            images: value,
            filesRequest: []
        }
        this.field = props.field;
        this.onChange = props.onChange;
        this.handleChange = this.handleChange.bind(this);
        this.handleRemoveFilesRequest = this.handleRemoveFilesRequest.bind(this);
        this.handlePostBlob = this.handlePostBlob.bind(this);
    }

    async handleChange(event) {
        const files = event.target.files;
        for (let i = 0; i < files.length; i++) {
            let fileRequest = {};
            fileRequest.name = files[i].name;
            fileRequest.type = files[i].type;
            fileRequest.base64 = await getBase64(files[i]);

            this.setState({
                images: this.state.images.concat(URL.createObjectURL(event.target.files[i])),
                filesRequest: this.state.filesRequest.concat(fileRequest)
            });
        }
    }

    async handlePostBlob(event) {
        if (this.state.filesRequest.length !== 0) {
            let responseData = await requests.postData("/blob", { "files": this.state.filesRequest }, constants.ACCESS_TOKEN);
            if (responseData.code === "200") {
                let key = this.field.key;
                let populateKey = this.field.auto_populate.key;
                let data = responseData.space.map(_data => _data[populateKey]);
                event.target.id = key;
                event.target.value = data;
                this.onChange(event);
                alert("Lưu ảnh lên 'Mây của bạn' thành công");
            } else {
                alert("Lưu ảnh lên 'Mây của bạn' không thành công.")
            }
        }
    }

    async handleRemoveFilesRequest() {
        this.setState({
            images: [],
            filesRequest: []
        })
    }

    render() {
        return (
            <div className="col-xs-12 col-12 col-md-12 p-3 text-center" style={{ alignContent: 'center' }}>
                <div className="container">
                    <div className="row">
                        {
                            this.state.images.length !== 0 ? (
                                this.state.images.map(image => (
                                    <div className="card thumbnail col-12 col-xs-12 col-md-3 float-left bg-white" style={{ height: '20rem' }}>
                                        <img style={{ width: "100%", height: "100%", objectFit: "contain" }}
                                            src={image.includes(this.DOMAIN) || image.includes("base64") ? image : `/blob/`.concat(image)}
                                        />

                                        <div className="card-footer">
                                            {!this.hiddenUuid ? <small>Link Img: <a href={this.DOMAIN.concat("/blob/", image)} className="card-link">{this.DOMAIN.concat("/blob/", image)}</a></small>
                                                : <></>}
                                        </div>
                                    </div>
                                ))
                            ) :
                                (
                                    <div className="card thumbnail col-12 col-xs-12 col-md-2 float-left bg-white" style={{ height: '15rem' }}>
                                        <img style={{ width: "100%", height: "100%", objectFit: "contain" }} src={emptyImage} />
                                    </div>
                                )
                        }
                    </div>
                </div>
                <div class="container">
                    <button className="btn btn-light row mt-3">
                        <label className="fileinput-new col-12 mb-3">{this.field.label}: </label>
                        <input className="col-12 col-md-8 mb-3" type="file" id="multiSelectedFile" multiple name="file" onChange={this.handleChange} />
                        <button className="btn btn-info btn-round col-12 col-md-6" onClick={this.handlePostBlob}><i class="bi bi-cloud-arrow-up"></i> Lưu Ảnh Hình Ảnh</button>
                        <button className="btn btn-danger btn-round col-12 col-md-6" onClick={this.handleRemoveFilesRequest}><i className="fa fa-times"></i> Xóa Ảnh Đã Chọn </button>
                    </button>
                </div>
            </div>
        )
    }
}


function getBase64(file) {
    return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => {
            let encoded = reader.result.toString().replace(/^data:(.*,)?/, '');
            if ((encoded.length % 4) > 0) {
                encoded += '='.repeat(4 - (encoded.length % 4));
            }
            resolve(encoded);
        };
        reader.onerror = error => reject(error);
    });
}

export default MultiSelected;