import {Form} from "react-bootstrap";
import React, {useState, useEffect} from 'react';

export default function CheckSwitches(props) {

    const { field, onChange } = props;
    const [changeValue, setChangeValue] = useState("false");


    useEffect(() => {
        setChangeValue(props.value);

        return () => {
            setChangeValue("false");
        }
    }, []);

    const switchChange = (event) => {
        const newVal = props.value === "true" ? "false" : "true";
        setChangeValue(newVal);
        event.target.value = newVal;
        event.preventDefault = () => {
        };
        onChange(event)
    }

    return (
        <div className="col-xs-12 col-12 col-md-12 p-1" key={field.key}>
                {
                    changeValue === "true" ?
                        (
                            <div className="custom-control custom-switch">
                                <input className="custom-control-input"
                                       id={field.key}
                                       type="checkbox"
                                       disabled={!field.editable}
                                       checked={true}
                                       onChange={switchChange}
                                />
                                <label className="custom-control-label" htmlFor={field.key}>NGƯNG XUẤT BẢN</label>
                            </div>
                        ) :
                        (
                            <div className="custom-control custom-switch">
                                <input className="custom-control-input"
                                       id={field.key}
                                       type="checkbox"
                                       disabled={!field.editable}
                                       onChange={switchChange}
                                />
                                <label className="custom-control-label" htmlFor={field.key}>{field.label}</label>
                            </div>
                        )
                }

        </div>
    )
}