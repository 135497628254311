import React from "react";

import Navbar from '../components/Navbar';
import Sidebar from '../components/Sidebar';
import ProductView from '../content/ProductView';

import requests from "../requests";
import constants from "../constants";

class ProductViewPage extends React.Component {
    constructor(props) {
        super(props);
        this.product_code = props.match.params.search_title;
        this.state = {
            product: null
        }
    }

    async componentWillMount() {
        let access_token = requests.getSessionStorage(constants.ACCESS_TOKEN);
        if ( access_token !== "") {
            requests.setCookie(constants.ACCESS_TOKEN, access_token, constants.LONG_EXPIRY_DATE);
        }
        let responseData = await requests.getData(`/api/workflow/product-detail/${this.product_code}`, constants.ACCESS_TOKEN);
        this.setState({
            product: responseData.space
        })
    }

    render() {
        return (
            <div className="g-sidenav-show  bg-gray-100">
                <Sidebar />
                <div id="content">
                    <Navbar navbarItems={constants.NAVBAR_PRODUCT_ITEMS}/>
                    <div className="container" >
                        <div className="row">
                            {this.state.product
                                ? (<ProductView product={this.state.product} />)
                                : (<div><p>Không tìm thấy sản phẩm có sản phẩm</p></div>)

                            }
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default ProductViewPage;