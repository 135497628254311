import React, { useState, useEffect } from "react";

import requests from "../../../requests";
import constants from "../../../constants";

import { Card, Form, Button, Modal } from 'react-bootstrap';

import FacebookLoginComponent from "./FacebookLoginComponent";
import FacebookReelsSubmit from "./FacebookReelsSubmit";


const FacebookSDKComponent = () => {

    const [accounts, setAccounts] = useState([]);
    const [userData, setUserData] = useState({});
    const [isLoggedIn, setIsLoggedIn] = useState(false);
    const [pages, setPages] = useState([]);
    const [metaApp, setMetaApp] = useState([]);

    const loadAccounts = async () => {

        let accounts = await callApiGetAccounts();

    };

    const callApiGetAppId = async () => {
        let access_token = requests.getSessionStorage(constants.ACCESS_TOKEN);
        if ( access_token !== "") {
            requests.setCookie(constants.ACCESS_TOKEN, access_token, constants.LONG_EXPIRY_DATE);
        }
        let api = `/api/workflow/product-listing?pageable=release_date,-1,1,1000`;
        let responseData = await requests.getData(api, constants.ACCESS_TOKEN);
        return responseData.space;
    }

    const callApiGetAccounts = async () => {
        let access_token = requests.getSessionStorage(constants.ACCESS_TOKEN);
        if ( access_token !== "") {
            requests.setCookie(constants.ACCESS_TOKEN, access_token, constants.LONG_EXPIRY_DATE);
        }
        let api = `/api/meta/accounts`;
        let responseData = await requests.getData(api, constants.ACCESS_TOKEN);
        return responseData.space;
    }

    const callApiGetAccountById = async () => {
        let access_token = requests.getSessionStorage(constants.ACCESS_TOKEN);
        if ( access_token !== "") {
            requests.setCookie(constants.ACCESS_TOKEN, access_token, constants.LONG_EXPIRY_DATE);
        }
        let api = `/api/workflow/product-listing?pageable=release_date,-1,1,1000`;
        let responseData = await requests.getData(api, constants.ACCESS_TOKEN);
        return responseData.space;
    }

    const callApiGetPages = async (userId, accessToken) => {
        const requestUrl = `https://graph.facebook.com/${userId}/accounts?access_token=${accessToken}`;
        return await requests.getData(requestUrl);
    }

    const callApiGetPagesWithGroup = async (group) => {
        // const requestUrl = `https://graph.facebook.com/${userId}/accounts?access_token=${accessToken}`;
        // return await requests.getData(requestUrl);

        let access_token = requests.getSessionStorage(constants.ACCESS_TOKEN);
        if ( access_token !== "") {
            requests.setCookie(constants.ACCESS_TOKEN, access_token, constants.LONG_EXPIRY_DATE);
        }
        let api = `/api/meta/pages?group=${group}`;
        let responseData = await requests.getData(api, constants.ACCESS_TOKEN);
        return responseData.space;
    }

    const handleLogout = () => {
        setIsLoggedIn(false);
        window.FB.logout(function (response) {
        });
    };

    useEffect(()=> {
        callApiGetAppId().then(result => {
            setMetaApp(result);
        })
        return () => {
            setMetaApp([]);
        }
    }, []);

    return (
        <div>
            {isLoggedIn ? (
                <div>
                    {pages.length !== 0 ? (
                            pages.map(page => (
                                <div key={page.id}>
                                    <div>-----------------------------------------------------------------------</div>
                                        <FacebookReelsSubmit page={page} />
                                    <div>-----------------------------------------------------------------------</div>
                                </div>
                            ))
                        ) :
                        (
                            <div>Không có danh sách pages</div>
                        )}
                    <button className="btn btn-danger" onClick={handleLogout}>Logout</button>
                </div>
            ) :
                <div/>
            }
        </div>
    )
};

export default FacebookSDKComponent;