import React, {useState, useEffect} from 'react';
import requests from '../requests';
import constants from '../constants';

const blobAPI = "/blob/";

async function loadFiles() {
    const api = blobAPI.concat("files?pageable=created_dated,-1,1,1000");
    let response = await requests.getData(api, constants.ACCESS_TOKEN);
    console.log(response);
    return response.space;
}

const LibraryContent = (props) => {

    const DOMAIN = props.domain;
    const [files, setFiles] = useState([]);
    const [loaded, setLoaded] = useState(false);

    useEffect(() => {
        loadFiles().then(result => {
            setFiles(result);
            setLoaded(true);
        }).catch()
        return () => {
            setFiles([])
            setLoaded(false);
        }
    }, []);

    if (loaded) {
        return (
            <div className="container-fluid">
                <div className="row">
                    {files.map(file =>
                        <div key={file.uuid} className="col-12 col-md-6 col-xl-4 p-3">
                            <div className="card" style={{width : "400px"}}>
                                <img className="card-img-top w-100" src={DOMAIN.concat(blobAPI, file.uuid)} alt={file.name} />
                                <div className="card-body">
                                    <h4 className="card-title">{file.name}</h4>
                                    <p className="card-text">{DOMAIN.concat(blobAPI, file.uuid)} <small>link copy</small></p>
                                </div>
                                <div className="card-footer"><small>Đăng vào lúc: {file.created_dated}</small></div>
                            </div>
                        </div>
                    )}
                </div>
            </div>
        )
    } else {
        return (
            <div className="container-fluid">
                <div className="row">
                    Không có dữ liệu
                </div>
            </div>
        )
    }
}

export default LibraryContent;