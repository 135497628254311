import {Form} from "react-bootstrap";
import React from "react";

export default function Input(props) {

    const onChange = props.onChange;

    const { field, value } = props;
    return (
        <div key={field.key} className="col-xs-12 col-12 col-md-6 p-1">
            <Form.Label>{field.label}</Form.Label>
            <input type={field.type} className="form-control radius"
                   id={field.key} placeholder={field.label.toLowerCase()}
                   disabled={!field.editable}
                   value={value}
                   onChange={onChange}
            />
        </div>
    )
}