import React from 'react';
import {Link} from 'react-router-dom';

export function buildTemplateDatagrid(listName, createFormLink) {
    return listName.map(name => {
        if (name === "id") {
            return {
                enableColumnFilter : true,
                accessorKey : name,
                id : name,
                header : name,
                accessorFn : data => (
                    <Link to={createFormLink + "?variantId=" + data.id}>
                        {data.id}
                    </Link>
                )
            }
        }
        return {
            enableColumnFilter : true,
            accessorKey : name,
            id : name,
            header : name
        }
    });
}
