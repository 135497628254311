import React from "react";

import Navbar from '../components/Navbar';
import Sidebar from '../components/Sidebar';
import OutboxDetail from '../content/OutboxDetail';

import requests from "../requests";
import constants from "../constants";


class OutboxDetailPage extends React.Component {
    constructor(props) {
        super(props);
        this.search_title = props.match.params.search_title;
        this.state = {
            outboxes: []
        }
    }

    async componentWillMount() {
        let access_token = requests.getSessionStorage(constants.ACCESS_TOKEN);
        if ( access_token !== "") {
            requests.setCookie(constants.ACCESS_TOKEN, access_token, constants.LONG_EXPIRY_DATE);
        }
        
        let responseData = await requests.getData(`/api/workflow/order/outbox/${this.search_title}`, constants.ACCESS_TOKEN)
        this.setState({
            outboxes: responseData.space
        })
    }

    render() {
        return (
            <div className="g-sidenav-show bg-gray-100">
                <Sidebar />
                <div id="content">
                    <Navbar />
                    <div className="container" >
                        <div className="row">
                            {this.state.outboxes.length !== 0
                                ? (<OutboxDetail outboxes={this.state.outboxes} />)
                                : (<div><p>Hiện tại chưa có đơn hàng</p></div>)
                            }
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default OutboxDetailPage;