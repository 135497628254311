import requests from "../../requests";
import constants from "../../constants";
import userDataService from "../../services/userDataService";
import util from "../../util/util";
import React, {useEffect, useState} from "react";
import Sidebar from "../../components/Sidebar";
import Navbar from "../../components/Navbar";
import LoadingPage from "../../page/LoadingPage";
import AbstractUpdateForm from "../createform/AbstractUpdateForm";
import AbstractCreateForm from "../createform/AbstractCreateForm";

export default function CategoryMasterCreateForm (props) {
    const product_code = util.parseQuery(props.location.search).product_code;
    const [loading, setLoading] = useState(false);
    const [fields, setFields] = useState([]);
    const [product, setProduct] = useState({});

    useEffect(()=> {
        initialCreateForm(product_code).then(result => {
            setFields(result.fields);
            setProduct(result.variant);
            setLoading(true);
        })
        return () => {
            setFields([]);
            setProduct({});
            setLoading(false);
        }
    }, []);

    if (Array.isArray(fields) && fields.length) {
        return (
            <div className="wrapper">
                <Sidebar />
                <div id="content" className="container-fluid">
                    <Navbar navbarItems={constants.NAVBAR_PRODUCT_ITEMS}/>
                    { loading ?
                        (product_code ?
                            (<AbstractUpdateForm
                                fields={fields} product={product}
                                updateApi={"/api/productservice/category/update"}
                                deleteApi={"/api/productservice/category/delete"}
                                redirectLocation={"/products"}
                            />) :
                            (<AbstractCreateForm
                                fields={fields} createApi={"/api/productservice/category/create"} redirectLocation={"/products"}
                            />))
                        : <LoadingPage />
                    }
                </div>
            </div>
        )
    } else {
        return (
            <div className="wrapper">
                <Sidebar />
                <div id="content" className="container-fluid">
                    <h4>Lỗi tải trang.</h4>
                </div>
            </div>
        )
    }
}


async function initialCreateForm(product_code) {
    let access_token = requests.getSessionStorage(constants.ACCESS_TOKEN);
    if ( access_token !== "") {
        requests.setCookie(constants.ACCESS_TOKEN, access_token, constants.LONG_EXPIRY_DATE);
    }

    let userData = await userDataService.loadUserData();
    const SPACE_NAME = userData.space;

    let categoryMaster = null;
    if (util.notNull(product_code)) {
        let responseProduct = await requests.postData(`/api/productservice/category/get`, { id : product_code, space : SPACE_NAME}, constants.ACCESS_TOKEN);
        categoryMaster = responseProduct.space;
    }
    let responseFields = await requests.getData(`/api/space/space-structure/category-master/create-form`, constants.ACCESS_TOKEN);
    return {
        fields: responseFields.createForm,
        categoryMaster: categoryMaster
    }
}