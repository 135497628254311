import React from "react";

import requests from "../../requests";
import constants from "../../constants";
import Statistic from "../../components/createform/Statistic";
import Selected from "../../components/populate/Selected";
import MultiSelected from "../../components/populate/MultiSelected";
import DetectedInput from "../../components/createform/DetectedInput";
import util from "../../util/util";

const DOMAIN = window.location.hostname;
export default class AbstractCreateForm extends React.Component {
    constructor(props) {
        super(props);
        this.fields = props.fields;
        this.product = props.product;
        this.createApi = props.createApi;
        this.redirectLocation = props.redirectLocation;
        this.createFields = util.notNull(this.product) ? this.product : {};
        this.state = {
            changed: {},
            editor: null
        }

        this.handleCreateRequest = this.handleCreateRequest.bind(this);
        this.handleOnChangeRequest = this.handleOnChangeRequest.bind(this);
        this.handleOnChangeRequestBlobStore = this.handleOnChangeRequestBlobStore.bind(this);
        this.handleSetupEditor = this.handleSetupEditor.bind(this);
    }

    handleCreateRequest() {

        requests.postData(`${this.createApi}`, this.createFields, constants.ACCESS_TOKEN).then(response => {
            if (response.message === "SUCCESS") {
                if (this.redirectLocation) {
                    window.location.href = this.redirectLocation;
                }
            } else {
                let message = response.message;
                alert(message);
            }
        });
    }

    handleOnChangeRequest(event) {
        event.preventDefault();
        const id = event.target.id;
        let val = null;
        if (event.target.type === "number") {
            val = event.target.valueAsNumber;
        } else if (event.target.type === "date") {
            val = event.target.valueAsDate;
        } else {
            val = event.target.value;
        }
        this.createFields[id] = val;
        console.log("call createFields on change: ", this.createFields);
        let objectChangeData = {};
        objectChangeData[id] = val;
        this.setState({
            changed: objectChangeData
        });
    }

    handleOnChangeRequestBlobStore(event) {
        event.preventDefault();
        const id = event.target.id;
        const val = event.target.value.split(",");
        this.createFields[id] = val;

        let objectChangeData = {};
        objectChangeData[id] = val;
        this.setState({
            changed: objectChangeData
        })
    }

    handleSetupEditor(editor) {
        this.setState({
            editor: editor
        });
    }

    render() {
        console.log("call create Fields when create component: ", this.createFields);
        let renderFields = this.fields.filter(field => !field.hidden);
        let filterField = renderFields.filter(field => field.type === "selected");
        let selectedField = Array.isArray(filterField) && filterField.length ? filterField : [];

        return (
            <div className="container-fluid col-12 col-md-9 card shadow mt-3">
                <div className="row">

                    {
                        selectedField.length !== 0 ? selectedField.map( selected => <Selected field={selected} domain={DOMAIN} onChange={this.handleOnChangeRequestBlobStore}/>) : <></>
                    }

                    <div className="form-row m-1">
                        {
                            renderFields.map(field => {
                                if (field.section === 'a') {
                                    if (field.type === "statistic") {
                                        if (field.auto_populate && field.auto_populate.require_key !== undefined) {
                                            return <Statistic key={field.key} field={field}
                                                              createFields={this.createFields}
                                                              changeData={this.state.changed}
                                                              onChange={this.handleOnChangeRequest}/>
                                        } else {
                                            return <Statistic key={field.key} field={field}
                                                              createFields={this.createFields}
                                                              onChange={this.handleOnChangeRequest}/>
                                        }
                                    } else if (field.type === "multiSelected") {
                                        return <MultiSelected field={field} createFields={this.createFields}
                                                              domain={DOMAIN}
                                                              onChange={this.handleOnChangeRequestBlobStore}/>
                                    } else {
                                        return <DetectedInput key={field.key} createFields={this.createFields}
                                                              editorProps={{editor: this.state.editor, setupEditor: this.handleSetupEditor}}
                                                              field={field} onChange={this.handleOnChangeRequest}/>
                                    }
                                }
                            })
                        }

                    </div>
                    <div>
                        <button type="button" className="m-3 btn btn-primary"
                                onClick={this.handleCreateRequest}><i className="bi bi-cloud-arrow-up"/> Đăng bài
                        </button>
                        <button type="button" className="m-3 btn btn-info float-right"><a href={this.redirectLocation}> Hủy </a>
                        </button>
                    </div>
                </div>
            </div>
        )
    }
}
